import { ErrorHandler, Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import Appsignal from '@appsignal/javascript';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

const appSignal = new Appsignal({
  key: environment.appSignalKey,
  revision: environment.version,
});

export const handleError = (error: Error) => {
  Device.getInfo().then((d) => {
    let deviceInfo: Record<string, string>;
    for (const [key, value] of Object.entries(d)) {
      deviceInfo = { ...deviceInfo, [key]: value };
    }

    // removes personal data
    delete deviceInfo?.name;
    delete deviceInfo?.memUsed;
    delete deviceInfo?.diskFree;
    delete deviceInfo?.diskTotal;
    delete deviceInfo?.realDiskFree;
    delete deviceInfo?.realDiskTotal;

    // send error to appSignal
    if (environment.production) {
      appSignal.send(error, (span) => {
        // set platform as namespace
        span.setNamespace(`${d.platform}`);
        span.setTags(deviceInfo);
      });
    } else {
      console.error(error);
    }
  });
};

@Injectable()
export class ErrorTrackingService implements ErrorHandler {
  constructor() {}

  handleError(error: Error) {
    // HttpErrors are handled in the error-tracking.interceptor
    if (error instanceof HttpErrorResponse === false) {
      handleError(error);
    }
  }
}
