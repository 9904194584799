import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { catchComplete } from 'app/utils';
import { ApiService } from 'store/api/api.service';
import * as MeasurementActions from 'store/measurements-store/measurements.actions';
import * as MonitoringGoalsActions from './monitoring-goals.actions';
import * as ActivationsActions from 'store/activations-store/activations.actions';

@Injectable()
export class MonitoringGoalsEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadMonitoringGoals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MonitoringGoalsActions.loadMonitoringGoals,
        MeasurementActions.addMeasurementsPartialSuccess,
        MeasurementActions.addEcgMeasurementsPartialSuccess,
        ActivationsActions.addActivationSuccess
      ),
      mergeMap((action) =>
        this.apiService.getMonitoringGoals().pipe(
          map((data) =>
            action.type === MonitoringGoalsActions.loadMonitoringGoals.type ||
            action.type === ActivationsActions.addActivationSuccess.type
              ? MonitoringGoalsActions.loadMonitoringGoalsSuccess({
                  entities: data.results,
                })
              : action.type === MeasurementActions.addMeasurementsPartialSuccess.type
              ? MeasurementActions.addMeasurementsSuccess({
                  data: action.data,
                  entities: data.results,
                })
              : MeasurementActions.addEcgMeasurementsSuccess({
                  data: action.data,
                  entities: data.results,
                })
          ),
          catchError((error) => of(MonitoringGoalsActions.loadMonitoringGoalsFailure({ error }))),
          catchComplete(() => of(MonitoringGoalsActions.loadMonitoringGoalsComplete({ action })))
        )
      )
    );
  });
}
