import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ApiService } from 'store/api/api.service';
import * as MeasurementResultsActions from './measurement-results.actions';
import { catchComplete } from 'app/utils';

@Injectable()
export class MeasurementResultsEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadMeasurementResults$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeasurementResultsActions.loadMeasurementResults),
      mergeMap((action) =>
        this.apiService.getMeasurementResults(action.from, action.to, action.cache).pipe(
          map((data) =>
            MeasurementResultsActions.loadMeasurementResultsSuccess({
              results: data.results,
            })
          ),
          catchError((error) =>
            of(MeasurementResultsActions.loadMeasurementResultsFailure({ error }))
          )
        )
      )
    );
  });

  loadMeasurementResultsByPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeasurementResultsActions.loadMeasurementResultsByPage),
      mergeMap((action) =>
        this.apiService.getMeasurementResultsByPage(action.page, action.size, true).pipe(
          map((data) =>
            MeasurementResultsActions.loadMeasurementResultsByPageSuccess({
              results: data.results,
            })
          ),
          catchError((error) =>
            of(MeasurementResultsActions.loadMeasurementResultsByPageFailure({ error }))
          ),
          catchComplete(() =>
            of(MeasurementResultsActions.loadMeasurementResultsByPageComplete({ action }))
          )
        )
      )
    );
  });

  updateMeasurementResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementResultsActions.updateMeasurementResult),
      mergeMap((action) =>
        this.apiService.updateMeasurementResult(action.id, action.note).pipe(
          map((measurementResult) => {
            return MeasurementResultsActions.updateMeasurementResultSuccess({ measurementResult });
          }),
          catchError((error) =>
            of(MeasurementResultsActions.updateMeasurementResultFailure({ error }))
          )
        )
      )
    )
  );
}
