import { Action, createAction, props } from '@ngrx/store';
import { MeasurementResults, PageSize } from 'app/models';

export const loadMeasurementResults = createAction(
  '[Measurement Results/API] Load Measurement Results',
  props<{ from: string; to?: string; cache?: boolean }>()
);

export const loadMeasurementResultsSuccess = createAction(
  '[Measurement Results/API] Load Measurement Results Success',
  props<{
    results: MeasurementResults[];
  }>()
);

export const loadMeasurementResultsFailure = createAction(
  '[Measurement Results/API] Load Measurement Results Failure',
  props<{ error: any }>()
);

export const loadMeasurementResultsByPage = createAction(
  '[Measurement Results/API] Load Measurement Results by Page',
  props<{ page: number; size: PageSize }>()
);

export const loadMeasurementResultsByPageSuccess = createAction(
  '[Measurement Results/API] Load Measurement Results by Page Success',
  props<{
    results: MeasurementResults[];
  }>()
);

export const loadMeasurementResultsByPageComplete = createAction(
  '[Measurement Results/API] Load Measurement Results by Page Complete',
  props<{ action: Action }>()
);

export const loadMeasurementResultsByPageFailure = createAction(
  '[Measurement Results/API] Load Measurement Results by Page Failure',
  props<{ error: any }>()
);

export const updateMeasurementResult = createAction(
  '[Measurement Results/API] Update Measurement Result',
  props<{ id: number; note: string }>()
);

export const updateMeasurementResultSuccess = createAction(
  '[Measurement Results/API] Update Measurement Result Success',
  props<{ measurementResult: MeasurementResults }>()
);

export const updateMeasurementResultFailure = createAction(
  '[Measurement Results/API] Update Measurement Result Failure',
  props<{ error: any }>()
);
