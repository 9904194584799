import { createAction, props } from '@ngrx/store';
import { Patient } from 'app/models';

export const loadInfo = createAction('[Info/API] Load Info');

export const loadInfoSuccess = createAction(
  '[Info/API] Load Info Success',
  props<{ data: Patient }>()
);

export const loadInfoFailure = createAction(
  '[Info/API] Load Info Failure',
  props<{ error: any }>()
);

export const updateInfo = createAction(
  '[Info/API] Update Info',
  props<{ data: Partial<Patient> }>()
);

export const updateInfoPartialSuccess = createAction(
  '[Info/API] Update Info Partial Success',
  props<{ data: Patient; updatedData: Partial<Patient> }>()
);

export const updateInfoSuccess = createAction('[Info/API] Update Info Success');

export const updateInfoFailure = createAction(
  '[Info/API] Update Info Failure',
  props<{ data: Patient }>()
);

/**
 * Actions for updating profile data with personalized data mandatory for tmc.
 * This can theoretically be unified with updateInfoSuccess if updateInfoPartialSuccess is removed.
 * At this point the implication of the refactoring are not known, so updating the mandatory data will be handled separately
 */
export const updateTmcMandatoryInfo = createAction(
  '[Info/API] Update Personal Info',
  props<{ data: Partial<Patient> }>()
);

export const updateTmcMandatoryInfoSuccess = createAction(
  '[Info/API] Update Personal Info Success',
  props<{ data: Partial<Patient> }>()
);

export const updateTmcMandatoryInfoFailure = createAction(
  '[Info/API] Update Personal Info Failure',
  props<{ errorMessage: string }>()
);
