import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
// import OneSignal from 'onesignal-cordova-plugin';
import { Badge } from '@capawesome/capacitor-badge';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of, from, Observable } from 'rxjs';
import { map, catchError, switchMap, withLatestFrom, concatMap } from 'rxjs/operators';
import { EnvironmentService } from 'app/services/environment.service';
import * as AuthActions from 'store/auth-store/auth.actions';
// import * as fromAuth from 'store/auth-store/auth.reducer';
import * as ActivationActions from 'store/activations-store/activations.actions';
import * as fromActivations from 'store/activations-store/activations.reducer';
import * as MedicationIntakesActions from 'store/medication-intakes-store/medication-intakes.actions';
import * as fromMedicationIntakes from 'store/medication-intakes-store/medication-intakes.reducer';
import * as MedicationActions from 'store/medications-store/medications.actions';
import * as fromMedications from 'store/medications-store/medications.reducer';
import * as MeasurementActions from 'store/measurements-store/measurements.actions';
import * as fromMeasurements from 'store/measurements-store/measurements.reducer';
import * as fromMonitoringGoals from 'store/monitoring-goals-store/monitoring-goals.reducer';
import * as NotificationActions from './notifications.actions';
import * as fromNotifications from './notifications.reducer';
import { Activation, ActivationCategory, Measurement, MedicationIntake } from 'app/models';
import { SubscriptionCategoryPipe } from 'app/shared/pipe/subscription-category.pipe';
import {
  LocalNotificationSchema,
  Schedule,
  LocalNotifications,
  LocalNotificationDescriptor,
  PendingResult,
} from '@capacitor/local-notifications';
import { NotificationType, ILocalNotificationType } from './notifications.model';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<
      | fromNotifications.State
      | fromMedicationIntakes.State
      | fromMedications.State
      | fromMeasurements.State
      | fromMonitoringGoals.State
    >,
    private platform: Platform,
    private environment: EnvironmentService,
    private subscriptionCategoryPipe: SubscriptionCategoryPipe,
  ) {}

  // initOneSignal$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(AuthActions.authenticateSuccess, AuthActions.addSignInDataSuccess),
  //     withLatestFrom(this.store.pipe(select(fromAuth.selectNotificationUuid))),
  //     map(([, uuid]) => {
  //       if (this.platform.is('hybrid')) {
  //         OneSignal.setAppId(this.environment.environment.oneSignalAppId);
  //         OneSignal.setExternalUserId(uuid);
  //       }
  //       return NotificationActions.initOneSignalSuccess();
  //     })
  //   );
  // });

  initBadge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authenticateSuccess, AuthActions.addSignInDataSuccess),
      switchMap(() =>
        this.getBadgeRequest().pipe(map(() => NotificationActions.initBadgeSuccess())),
      ),
    );
  });

  // removeExternalUserId$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(AuthActions.signOutSuccess),
  //     map(() => {
  //       if (this.platform.is('hybrid')) {
  //         OneSignal.removeExternalUserId();
  //       }

  //       return NotificationActions.removeOneSignalExternalUserIdSuccess();
  //     })
  //   );
  // });

  addPendingNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.addPendingNotifications),
      switchMap(() =>
        this.getPendingNotificationsRequest().pipe(
          map((pendingResult: PendingResult) =>
            NotificationActions.addPendingNotificationsSuccess({
              notifications: pendingResult.notifications.map((notification) => ({
                ...notification,
                // schedule at property needs to be converted to Date,
                // otherwise wrong Date format error is thrown if notification is dispatched
                schedule: { ...notification.schedule, at: new Date(notification.schedule.at) },
              })),
            }),
          ),
          catchError((error) => of(NotificationActions.addPendingNotificationsFailure({ error }))),
        ),
      ),
    );
  });

  deleteCustomNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.deleteCustomNotifications),
      switchMap((action) =>
        this.getDeleteRequest(action.notifications).pipe(
          map(() =>
            NotificationActions.deleteCustomNotificationsSuccess({
              notifications: action.notifications,
            }),
          ),
          catchError((error) =>
            of(NotificationActions.deleteCustomNotificationsFailure({ error })),
          ),
        ),
      ),
    );
  });

  deleteNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationIntakesActions.loadMedicationIntakesSuccess,
        MedicationIntakesActions.updateMedicationIntakeSuccess,
        MedicationIntakesActions.updateMedicationIntakesSuccess,
        MedicationActions.loadMedicationsSuccess,
        MedicationActions.deleteMedicationSuccess,
        MeasurementActions.loadMeasurementsSuccess,
        ActivationActions.loadActivations,
        ActivationActions.loadActivationsSuccess,
        NotificationActions.addCustomNotifications,
        NotificationActions.deleteCustomNotificationsSuccess,
      ),
      withLatestFrom(this.store.pipe(select(fromNotifications.selectIds))),
      switchMap(([action, ids]) =>
        this.getDeleteRequest(
          ids.map((id) => ({
            id: parseInt(`${id}`, 0),
          })),
        ).pipe(
          map(() =>
            action.type === NotificationActions.addCustomNotifications.type
              ? NotificationActions.deleteNotificationsSuccess({
                  notifications: action.notifications.map((notification) => ({
                    ...notification,
                    extra: { ...notification.extra, type: NotificationType.Custom },
                  })),
                })
              : NotificationActions.deleteNotificationsSuccess({}),
          ),
          catchError((error) => of(NotificationActions.deleteNotificationsFailure({ error }))),
        ),
      ),
    );
  });

  loadNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.deleteNotificationsSuccess),
      switchMap((action) =>
        this.isLocalNotificationsEnabled().pipe(
          withLatestFrom(
            this.store.pipe(select(fromMedicationIntakes.selectMedicationIntakesFromToday)),
            this.store.pipe(select(fromMedications.selectMorningMedications)),
            this.store.pipe(select(fromMedications.selectNoonMedications)),
            this.store.pipe(select(fromMedications.selectEveningMedications)),
            this.store.pipe(select(fromMedications.selectNightMedications)),
            this.store.pipe(select(fromMeasurements.selectMeasurementsFromTodayWithoutEcg)),
            this.store.pipe(select(fromMonitoringGoals.selectEcgMonitoringGoal)),
            this.store.pipe(select(fromMonitoringGoals.selectSurveyMonitoringGoal)),
            this.store.pipe(select(fromActivations.selectAll)),
            this.store.pipe(select(fromNotifications.selectCustomNotifications)),
          ),
          map(
            ([
              isNotificationsEnabled,
              intakesToday,
              morningMedicationCount,
              noonMedicationCount,
              eveningMedicationCount,
              nightMedicationCount,
              measurements,
              ecgGoal,
              surveyGoal,
              activations,
              storedCustomNotifications,
            ]) => {
              let notifications: LocalNotificationSchema[] = [];
              const today = new Date();

              const hasEnqueuedActivations =
                activations.find((s) => s.status === 'enqueued') !== undefined;

              const currentSubscription = activations.find((s) => s.status === 'active');

              if (currentSubscription?.category !== 'tmc_eheart') {
                const hasOpenMedicationIntakes =
                  intakesToday.filter((i) => i.status === 'UNKNOWN').length > 0;

                //only tmc users gets an action-notification for a missing wellbeing survey
                const hasOpenSurveys = ['telemedicine_center', 'telemedicine_center_test'].includes(
                  currentSubscription?.category,
                )
                  ? surveyGoal?.open_measurements.filter((m) => m.survey_id === 'wellbeing')
                      .length > 0
                  : false;

                // checks if the user has an open ecg measurement for today
                // always false if the ecg-goal is undefined
                const hasOpenEcgMeasurment = ecgGoal
                  ? ecgGoal?.last_results[0] &&
                    dayjs().isSame(ecgGoal.last_results[0].device_time, 'day')
                    ? false
                    : true
                  : false;

                // generates notifications consisting of medicationIntakeNotifications + measurementNotifications
                const medicationIntakeNotifications = this.initMedicationIntakeNotifications(
                  today,
                  7, // generates max 28 medicationIntakeNotifications for 7 days
                  intakesToday,
                  morningMedicationCount.length,
                  noonMedicationCount.length,
                  eveningMedicationCount.length,
                  nightMedicationCount.length,
                );

                const measurementNotifications = this.initNotifications(
                  today,
                  8, // generates 8 measurementNotifications for 8 days
                  this.hasMissingMeasurement(measurements, currentSubscription?.category) ||
                    hasOpenEcgMeasurment,
                  [11],
                  NotificationType.Measurement,
                );

                const actionNotifications = this.initNotifications(
                  today,
                  7, // generates 7 actionNotifications for 7 days
                  this.hasMissingMeasurement(measurements, currentSubscription?.category) ||
                    hasOpenMedicationIntakes ||
                    hasOpenSurveys ||
                    hasOpenEcgMeasurment,
                  [20],
                  NotificationType.Action,
                  $localize`Messung, Medikamenteneinnahme oder Fragebogen noch nicht eingetragen`,
                );

                const appNotifications = this.initNotifications(
                  dayjs(today).add(8, 'day').toDate(),
                  4, // generates 4 appNotifications for 4 days
                  true,
                  [15],
                  NotificationType.App,
                  $localize`Bitte App öffnen`,
                );

                // subscription-notifications are depending on app-subscription-types
                const subscriptionNotifications =
                  !hasEnqueuedActivations &&
                  ['grace', 'diga', 'shop', 'shop_test', 'study'].includes(
                    currentSubscription?.category,
                  )
                    ? this.initSubscriptionNotifications(currentSubscription, [14, 7, 3, 1])
                    : !hasEnqueuedActivations && currentSubscription?.category === 'test'
                      ? this.initSubscriptionNotifications(currentSubscription, [3, 1])
                      : [];

                let customNotifications =
                  action.notifications !== undefined
                    ? [...storedCustomNotifications, ...action.notifications]
                    : [...storedCustomNotifications];

                // delete customNotifications with a schedule date in the past
                customNotifications = customNotifications.filter((notification) =>
                  dayjs(notification.schedule?.at).isSameOrAfter(dayjs(), 'milliseconds'),
                );

                notifications = [
                  ...medicationIntakeNotifications,
                  ...measurementNotifications,
                  ...actionNotifications,
                  ...appNotifications,
                  ...subscriptionNotifications,
                  ...customNotifications,
                ].map((n, i) => ({ ...n, id: i }));

                if (notifications.length > 64) {
                  console.error(
                    `${notifications.length} notifications exceeds iOS limit of 64 notifications per app.`,
                  );
                }
              }

              // schedules notifications when the app is running on iOS or android and notifications are enabled
              if (isNotificationsEnabled) {
                LocalNotifications.schedule({ notifications });
              }

              return NotificationActions.loadNotificationsSuccess({ notifications });
            },
          ),
        ),
      ),
    );
  });

  /**
   * checks if there are missing measurements for today based on
   * the measurement- and the app-subscription-type
   */
  private hasMissingMeasurement(measurements: Measurement[], category: ActivationCategory) {
    const hasWeightResult = measurements.find((m) => m.type === 'weight')?.results?.length > 0;
    const hasHeartRateResult =
      measurements.find((m) => m.type === 'heart_rate')?.results?.length > 0;
    const hasBloodPressureResult =
      measurements.find((m) => m.type === 'blood_pressure')?.results?.length > 0;

    // blood-pressure and weight-measurements are mandatory for tmc-users
    if (['telemedicine_center', 'telemedicine_center_test'].includes(category)) {
      return !(hasBloodPressureResult && hasWeightResult);
    }

    // blood-pressure, heart-rate and weight-measurements are mandatory for non tmc-users
    return !(hasBloodPressureResult && hasWeightResult && hasHeartRateResult);
  }

  private initNotifications(
    startDate: Date,
    dayCount: number, // set for how many days notifications will be generated
    shouldStartOnStartDate: boolean,
    hours: number[] = [11],
    type: NotificationType,
    text = $localize`Bitte denke an Deine Messungen!`,
  ) {
    const date = new Date(startDate);
    let notifications: ILocalNotificationType[] = [];
    let count = 0;
    do {
      date.setDate(
        date.getDate() +
          (count > 0 && shouldStartOnStartDate ? 1 : 0) +
          (shouldStartOnStartDate ? 0 : 1),
      );
      notifications = [
        ...notifications,
        ...this.generateNotifications(date, hours, text, { type }),
      ];
      count++;
    } while (count < dayCount);
    return notifications;
  }

  private initSubscriptionNotifications(subscription: Activation, scheduleByDays: number[]) {
    let notifications: ILocalNotificationType[] = [];
    scheduleByDays.forEach((days) => {
      notifications = notifications.concat(
        this.initNotifications(
          dayjs(subscription.end_date).subtract(days, 'days').toDate(),
          1,
          true,
          [10],
          NotificationType.Subscription,
          $localize`${this.subscriptionCategoryPipe.transform(
            subscription.category,
          )} läuft in ${days} ${days > 1 ? 'Tagen' : 'Tag'} ab`,
        ),
      );
    });
    return notifications;
  }

  initMedicationIntakeNotifications(
    startDate: Date,
    dayCount = 1, // set for how many days notifications will be generated
    intakesToday: MedicationIntake[],
    morningMedicationCount: number,
    noonMedicationCount: number,
    eveningMedicationCount: number,
    nightMedicationCount: number,
  ) {
    // get number of medical intakes not taken by daytime (morning, noon, evening, night)
    const morningNotTakenCount = intakesToday.filter(
      (e) => e.part_of_day === 'morning' && e.status === 'UNKNOWN',
    ).length;
    const noonNotTakenCount = intakesToday.filter(
      (e) => e.part_of_day === 'noon' && e.status === 'UNKNOWN',
    ).length;
    const eveningNotTakenCount = intakesToday.filter(
      (e) => e.part_of_day === 'evening' && e.status === 'UNKNOWN',
    ).length;
    const nightNotTakenCount = intakesToday.filter(
      (e) => e.part_of_day === 'night' && e.status === 'UNKNOWN',
    ).length;

    // set notification starting hour per daytime (morning, noon, evening, night)
    const notificationHours: {
      notTakenCount: number;
      medicationCount: number;
      hour: number;
    }[] = [
      { notTakenCount: morningNotTakenCount, medicationCount: morningMedicationCount, hour: 10 },
      { notTakenCount: noonNotTakenCount, medicationCount: noonMedicationCount, hour: 14 },
      { notTakenCount: eveningNotTakenCount, medicationCount: eveningMedicationCount, hour: 20 },
      { notTakenCount: nightNotTakenCount, medicationCount: nightMedicationCount, hour: 23 },
    ];

    const date = new Date(startDate);
    let notifications: ILocalNotificationType[] = [];
    let count = 0;
    do {
      date.setDate(date.getDate() + (count > 0 ? 1 : 0));
      if (dayjs().isSame(date, 'day')) {
        // set todays medical intakes notifications
        notificationHours.map(
          (h) =>
            (notifications =
              h.notTakenCount > 0
                ? [...notifications, ...this.generateMedicationIntakeNotifications(date, h.hour)]
                : notifications),
        );
      } else {
        // set medical intake notification for the next days
        notificationHours.map(
          (h) =>
            (notifications =
              h.medicationCount > 0
                ? [...notifications, ...this.generateMedicationIntakeNotifications(date, h.hour)]
                : notifications),
        );
      }
      count++;
    } while (count < dayCount);
    return notifications;
  }

  ngrxOnInitEffects(): any {
    return NotificationActions.addPendingNotifications();
  }

  private generateMedicationIntakeNotifications(date: Date, startHour: number) {
    const notifications: ILocalNotificationType[] = [];

    const notification: ILocalNotificationType = {
      title: '',
      body: $localize`Medikamenteneinnahmen ausstehend`,
      schedule: {
        at: new Date(date.getFullYear(), date.getMonth(), date.getDate(), startHour),
      },
      extra: { type: 'medicationIntakeNotification' },
    };

    // only add notification when the schedule date/time is in the future
    if (dayjs().isSame(date, 'day') && date.getHours() < startHour) {
      notifications.push(notification);
    } else if (!dayjs().isSame(date, 'day')) {
      notifications.push(notification);
    }

    return notifications;
  }

  private generateNotifications(
    date: Date,
    hours: number[],
    body: string,
    extra: any,
  ): ILocalNotificationType[] {
    const notification = (schedule: Schedule): ILocalNotificationType => {
      return {
        title: '',
        body,
        extra,
        schedule,
      };
    };
    return hours
      .map((h) =>
        notification({
          at: new Date(date.getFullYear(), date.getMonth(), date.getDate(), h),
        }),
      )
      .filter((n) => dayjs(n.schedule.at).isSameOrAfter(dayjs(), 'milliseconds'));
  }

  private getDeleteRequest(notifications: LocalNotificationDescriptor[]) {
    return this.isLocalNotificationsEnabled().pipe(
      concatMap((isEnabled) =>
        isEnabled && notifications.length > 0
          ? (from(LocalNotifications.cancel({ notifications })) as Observable<undefined>)
          : of({}),
      ),
    );
  }

  private getPendingNotificationsRequest(): Observable<PendingResult> {
    return this.isLocalNotificationsEnabled().pipe(
      concatMap((isEnabled) =>
        isEnabled ? from(LocalNotifications.getPending()) : of({ notifications: [] }),
      ),
    );
  }

  private getBadgeRequest() {
    return from(Badge.checkPermissions()).pipe(
      concatMap((r) =>
        r.display === 'denied' || r.display === 'granted'
          ? of(r)
          : from(Badge.requestPermissions()),
      ),
    );
  }

  /**
   * checks if the local notifications are enabled on the users devise.
   * alwas returns false on non-hybrid platforms.
   */
  private isLocalNotificationsEnabled() {
    return this.platform.is('hybrid') === false
      ? of(false)
      : from(LocalNotifications.checkPermissions()).pipe(
          concatMap((permissions) => (permissions.display === 'granted' ? of(true) : of(false))),
        );
  }
}
