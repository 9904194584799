import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { ApiService } from 'store/api/api.service';
import * as ApiModels from 'app/models/api.model';
import * as fromMedicationIntakes from 'store/medication-intakes-store/medication-intakes.reducer';
import { BehaviorSubject, combineLatest, fromEvent, merge, of, throwError } from 'rxjs';
import {
  map,
  first,
  distinctUntilChanged,
  skipWhile,
  switchMap,
  mapTo,
  mergeMap,
  concatMap,
  catchError,
} from 'rxjs/operators';
import * as AppActions from 'store/app.actions';
import * as fromApp from 'store/app.reducer';
import * as fromRouterSelectors from 'store/router-store/router.selectors';
import * as NotificationActions from 'store/notifications-store/notifications.actions';
import * as fromActivations from 'store/activations-store/activations.reducer';
import { Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AppStoreFacade {
  private FORM_OPTIONS_LOADING$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  formOptionsLoading$ = this.FORM_OPTIONS_LOADING$.asObservable();
  formOptions$: BehaviorSubject<ApiModels.FormOptions> = new BehaviorSubject(null);

  isOffline$ = new BehaviorSubject(false);
  isActive$ = new BehaviorSubject(true); // app is active or in background mode
  appIsDisabled$ = combineLatest([
    this.store.pipe(select(fromActivations.selectHasValidActivation)),
    this.isOffline$,
  ]).pipe(
    skipWhile(([hasValidSubscription]) => hasValidSubscription === null),
    distinctUntilChanged(),
    map(([hasValidSubscription, isOffline]) => hasValidSubscription === false || isOffline === true)
  );

  appIsActiveAndLoggedIn$ = combineLatest([
    this.store.pipe(select(fromActivations.selectHasValidActivation)),
    this.isActive$,
  ]).pipe(
    map(([hasValidSubscription, isActive]) => hasValidSubscription === true && isActive === true)
  );

  animation$ = this.store.pipe(select(fromApp.selectAnimation));

  previousRoute$ = this.store.pipe(select(fromApp.selectPreviousRoute));
  previousQueryParams$ = this.store.pipe(select(fromApp.selectPreviosQueryParams));

  constructor(
    private store: Store<fromMedicationIntakes.State | fromRouterSelectors.State>,
    private api: ApiService,
    private zone: NgZone,
    private platform: Platform
  ) {
    // handle network-status change
    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false)),
      of(this.platform.is('hybrid')).pipe(
        switchMap((hybrid) =>
          hybrid === true
            ? Network.addListener('networkStatusChange', (s) => s.connected)
            : of(true)
        )
      )
    ).subscribe((isOnline) => {
      this.zone.run(() => {
        this.isOffline$.next(!isOnline);
      });
    });
  }

  showHttpErrorMessage(error: HttpErrorResponse) {
    this.store.dispatch(AppActions.showHttpErrorMessage({ error }));
  }

  navigate(commands: any[], delay = 0) {
    this.store.dispatch(AppActions.navigate({ commands, delay }));
  }

  /**
   * Form Options
   */
  loadFormOptions() {
    this.formOptions$.pipe(first()).subscribe((o) => {
      if (o !== null) {
        return;
      }
      this.FORM_OPTIONS_LOADING$.next(true);
      this.api
        .getFormOptions()
        .pipe(first())
        .subscribe((r) => {
          this.FORM_OPTIONS_LOADING$.next(false);
          this.formOptions$.next(r);
        });
    });
  }

  /**
   * Notifications
   */
  initOneSignal() {
    this.store.dispatch(NotificationActions.initOneSignal());
  }

  /**
   * Data Export
   */
  requestDataExport() {
    return this.api.requestDataExport();
  }

  /**
   * 3rd party licenses
   */
  getThirdPartyLicenses() {
    return this.api.getThirdPartyLicenses();
  }

  loadAppConfig() {
    return this.api.getAppConfig().pipe(
      mergeMap((response) =>
        of(response).pipe(
          concatMap((response) => this.isSignatureValid(response)),
          map((isValid) => ({ ...response, auto_update: isValid ? response.auto_update : false }))
        )
      )
    );
  }

  private stringToArrayBuffer(str: any) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  private isSignatureValid(response: {
    auto_update: boolean;
    timestamp: string;
    signature: string;
  }) {
    const { signature, ...payload } = response;
    const decodedSignature = window.atob(signature);
    return this.api.getVerificationPublicKey().pipe(
      concatMap((pub) => {
        // fetch the part of the PUB string between header and footer
        const pubHeader = '-----BEGIN PUBLIC KEY-----';
        const pubFooter = '-----END PUBLIC KEY-----';
        const pubContents = pub.substring(pubHeader.length, pub.length - pubFooter.length);
        // base64 decode the string to get the binary data
        const binaryDerString = window.atob(pubContents);

        // convert from a binary string to an ArrayBuffer
        const binaryDer = this.stringToArrayBuffer(binaryDerString);
        return window.crypto.subtle.importKey(
          'spki',
          binaryDer,
          {
            name: 'RSASSA-PKCS1-v1_5',
            hash: 'SHA-256',
          },
          false,
          ['verify']
        );
      }),
      concatMap((key) =>
        crypto.subtle.verify(
          'RSASSA-PKCS1-v1_5',
          key,
          this.stringToArrayBuffer(decodedSignature),
          this.stringToArrayBuffer(JSON.stringify(payload))
        )
      ),
      catchError((error) => throwError(() => new Error(error)))
    );
  }
}
