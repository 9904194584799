import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppStoreResolver } from 'app/app-store-resolver/app-store.resolver';
import { AuthGuard } from 'app/guards';
import { BASE_APP_PATH, LOGIN_URL } from './shared/shared.routes';

const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  {
    path: 'welcome',
    loadChildren: () => import('pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
    data: { androidBackButtonCloseApp: true },
  },
  {
    path: LOGIN_URL,
    loadChildren: () => import('pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('pages/registration/registration.module').then((m) => m.RegistrationPageModule),
  },
  {
    path: BASE_APP_PATH,
    loadChildren: () =>
      import('./pages/navigation/navigation.module').then((m) => m.NavigationPageModule),
    resolve: { state: AppStoreResolver },
    data: { type: 'initApp' },
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
