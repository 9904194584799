import {
  Component,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import {
  MonitoringGoal,
  ObservationResults,
  ObservationResult,
  ObservationResultBloodPressure,
  ObservationResultEcg,
  ObservationResultSurvey,
  ObservationResultWeight,
} from 'app/models';
import { isEqual, orderBy, sum as loadashSum, flatten } from 'lodash-es';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { ValueFormatPipe } from 'shared/pipe/value-format.pipe';
import { Router } from '@angular/router';
import { isOfType } from 'app/utils';

dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(LocalizedFormat);
require('dayjs/locale/de');
require('dayjs/locale/fr');

export interface GraphPoint {
  min: number;
  max: number;
  avg: number;
}

export interface PageIndicators {
  areas: {
    top: number;
    bottom: number;
    color: string;
  }[];
  lines: number[];
  unit: string;
}

export interface ChartInput {
  monitoringGoal: MonitoringGoal;
  entities: Record<
    string,
    (
      | ObservationResult
      | ObservationResultWeight
      | ObservationResultBloodPressure
      | ObservationResultEcg
      | ObservationResultSurvey
    )[]
  >;
  dateToShow: string | null;
  minDate: string;
}

export interface ChartPageItem {
  showItem: boolean;
  showLegend: boolean;
  title: string;
  labels: any[];
  legend: string;
  graphPoints: GraphPoint[];
  from: string;
  to: string;
}

export interface ChartPage {
  pageNumber: number;
  leftBorderValue: number[];
  rightBorderValue: number[];
  items: ChartPageItem[];
  from: string;
  to: string;
}

type GraphItemRange = 'day' | 'week' | 'month';

@Component({
  selector: 'pro-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ValueFormatPipe],
})
export class ChartComponent implements OnChanges {
  @Input() chartData: ChartInput;

  @Output() emitReload: EventEmitter<string> = new EventEmitter();

  dayFormat = 'YYYY-MM-DD';

  timeRangeSettings = {
    day: {
      itemsPerPage: 7,
      reloadThreshold: 6,
    },
    week: {
      itemsPerPage: 12,
      reloadThreshold: 24,
    },
    month: {
      itemsPerPage: 12,
      reloadThreshold: 58,
    },
  };

  pageItemBoundaries: {
    [key in GraphItemRange]: {
      from: string;
      to: string;
    }[];
  };

  chartPages: {
    [key in GraphItemRange]: ChartPage[];
  };

  globalBoundaries: Record<string, string> = {
    from: null,
    to: dayjs().format(this.dayFormat),
  };

  resultsSummary: GraphPoint[] = [];
  pagesToDraw: ChartPage[] = [];
  selectedItemRange: GraphItemRange = 'day';
  currentPage = 1;
  totalPages = 0;
  showPrev = true;
  showNext = true;
  displayedRange = '';
  autoscroll = true;

  pageIndicators: PageIndicators = {
    areas: [],
    lines: [],
    unit: '',
  };

  indicatorColors = {
    good: 'var(--ion-color-success)',
    critical: 'var(--pro-color-egg-yolk-sunrise)',
  };

  constructor(
    private valueFormatPipe: ValueFormatPipe,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.chartData && changes.chartData?.currentValue !== null) {
      const input: ChartInput = changes.chartData.currentValue;

      if (!changes.chartData.previousValue) {
        if (!input.monitoringGoal) {
          return;
        }

        this.globalBoundaries.from = input.minDate;
        this.createPageItemsBoundaries();
      }

      if (
        isEqual(changes.chartData.currentValue, changes.chartData.previousValue) === false &&
        Object.keys(changes.chartData.currentValue.entities).length > 0
      ) {
        this.createChartData();
      }
    }
  }

  /**
   * Invokes methods to create necessary data to draw a graph.
   * Sets buttons visibility
   * Sets pages to draw
   */
  createChartData() {
    if (this.chartData.monitoringGoal.type !== 'EcgGoal') {
      this.resultsSummary = this.getMinMaxAvgFromMeasurements(
        flatten(Object.values(this.chartData.entities)),
      );

      this.createIndicators();
      this.createLineChartPages();
    } else {
      this.createDocChartPages();
    }

    // Set new current-page, if date was provided as param
    if (this.chartData.dateToShow !== null && this.autoscroll === true) {
      this.autoscroll = false;
      this.currentPage = this.getPageByDate(this.selectedItemRange, this.chartData.dateToShow);
    }

    this.updateNavigation();
    this.selectPagesToDraw();
  }

  /**
   * Gets data to draw horizontal background lines and colored areas to illustrate value-ranges
   * Values depends on current goal-type
   * Returns if no values where loaded yet
   */
  createIndicators() {
    if (!this.resultsSummary.length) return;

    const goal = this.chartData.monitoringGoal;
    const valueIndicators: PageIndicators = {
      lines: [],
      areas: [],
      unit: goal.result_unit,
    };

    // horizontal lines
    if (goal.type === 'BloodPressureGoal') {
      valueIndicators.lines = [
        this.resultsSummary[0].max,
        this.resultsSummary[0].avg,
        this.resultsSummary[1].avg,
        this.resultsSummary[1].min,
      ].map((value) => Math.round(value));
    } else {
      valueIndicators.lines.push(this.resultsSummary[0].max);

      if (goal.type === 'WeightGoal' || goal.type === 'TemperatureGoal') {
        valueIndicators.lines.push(+this.resultsSummary[0].avg.toFixed(1));
      } else {
        valueIndicators.lines.push(Math.round(this.resultsSummary[0].avg));
      }

      valueIndicators.lines.push(this.resultsSummary[0].min);
    }

    // background areas
    if (goal.type === 'BloodPressureGoal') {
      valueIndicators.areas = [
        {
          top: +goal.critical_max_systolic,
          bottom: +goal.max_systolic,
          color: this.indicatorColors.critical,
        },
        {
          top: +goal.max_systolic,
          bottom: +goal.min_systolic,
          color: this.indicatorColors.good,
        },
        {
          top: +goal.min_systolic,
          bottom: +goal.critical_min_systolic,
          color: this.indicatorColors.critical,
        },
      ];
    } else if (goal.type === 'HeartRateGoal' || goal.type === 'TemperatureGoal') {
      valueIndicators.areas = [
        {
          top: +goal.critical_max,
          bottom: +goal.max,
          color: this.indicatorColors.critical,
        },
        {
          top: +goal.max,
          bottom: +goal.min,
          color: this.indicatorColors.good,
        },
        {
          top: +goal.min,
          bottom: +goal.critical_min,
          color: this.indicatorColors.critical,
        },
      ];
    } else if (goal.type === 'OxygenSaturationGoal') {
      valueIndicators.areas = [
        {
          top: 100,
          bottom: +goal.min,
          color: this.indicatorColors.good,
        },

        {
          top: +goal.min,
          bottom: +goal.critical_min,
          color: this.indicatorColors.critical,
        },
      ];
    }
    this.pageIndicators = valueIndicators;
  }

  /**
   * Completes data to create line-chart-pages for every time-range
   */
  createLineChartPages() {
    this.chartPages = {
      week: [],
      month: [],
      day: [],
    };

    Object.keys(this.pageItemBoundaries).map((timeRange: GraphItemRange) => {
      // get all items to show
      const pageItems = this.createLineChartItems(timeRange);
      // fill missing measurement-results with placeholder values
      this.assignPlaceholderValue(pageItems);
      // chunk page-items to get single pages
      const chunkedPageItems = this.chunkArray(
        pageItems,
        this.timeRangeSettings[timeRange].itemsPerPage,
      );
      // finalize line-chart-pages
      this.chartPages[timeRange] = this.completeLineChartPages(chunkedPageItems);
    });
  }

  /**
   * Assigns available measurement-results to corresponding page-item.
   * Calculates points to show in graph and assigns labels and legend to each item.
   */
  createLineChartItems(timeRange: GraphItemRange) {
    return this.pageItemBoundaries[timeRange].map((item, index) => {
      let currentDate = dayjs(item.from);
      // Collects all measurements within page-item
      const resultsInItem: (
        | ObservationResult
        | ObservationResultWeight
        | ObservationResultBloodPressure
        | ObservationResultEcg
        | ObservationResultSurvey
      )[] = [];

      while (currentDate.isBetween(item.from, item.to, 'day', '[]') === true) {
        const results = this.chartData.entities[currentDate.format(this.dayFormat)];
        if (results) {
          resultsInItem.push(...results);
        }
        currentDate = currentDate.add(1, 'day');
      }
      // Gets summary for page-item
      const graphPoints = this.getMinMaxAvgFromMeasurements(resultsInItem);

      // Creates labels for page-item
      const labels =
        timeRange === 'day'
          ? this.createListedLabels(resultsInItem)
          : this.createMergedLabels(graphPoints);

      // Toggles legends for weekly time-range
      const showLegend = timeRange === 'week' && index % 2 === 0 ? false : true;

      return {
        showItem: resultsInItem.length > 0,
        showLegend,
        title: this.extractRange(item.from, item.to),
        legend: this.extractLegend(timeRange, item.to),
        labels,
        graphPoints,
        from: item.from,
        to: item.to,
      };
    });
  }

  /**
   * Creates chart pages for ect-monitoring-goal over all time-ranges
   */
  createDocChartPages() {
    this.chartPages = {
      week: [],
      month: [],
      day: [],
    };

    Object.keys(this.pageItemBoundaries).map((timeRange: GraphItemRange) => {
      // Gets all items to show
      const pageItems = this.createDocChartItems(timeRange);
      // Chunks page-items to get single pages
      const chunkedPageItems = this.chunkArray(
        pageItems,
        this.timeRangeSettings[timeRange].itemsPerPage,
      );

      // Creates page-objects
      this.chartPages[timeRange] = chunkedPageItems.map((page, index) => {
        return {
          pageNumber: index + 1,
          leftBorderValue: null,
          rightBorderValue: null,
          items: page,
          from: page[0].from,
          to: page[page.length - 1].to,
        };
      });
    });
  }

  /**
   * Creates single pages for ecg-monitoring-goal for a given time-range
   * @param timeRange Time-range to create chart-items for
   */
  createDocChartItems(timeRange: GraphItemRange) {
    return this.pageItemBoundaries[timeRange].map((item, index) => {
      const labels: {
        text: string;
        documentId: number;
      }[] = [];
      let currentDate = dayjs(item.from);

      // Collects all measurements within page-item
      const resultsInItem: ObservationResultEcg[] = [];

      while (currentDate.isBetween(item.from, item.to, 'day', '[]') === true) {
        const results = this.chartData.entities[currentDate.format(this.dayFormat)];
        if (results && isOfType<ObservationResultEcg[]>(results, 'document_id')) {
          resultsInItem.push(...results);
        }
        currentDate = currentDate.add(1, 'day');
      }

      // Creates labels and add documentId
      orderBy(resultsInItem, 'device_time', 'desc').map((result) => {
        let text = `${dayjs(result.device_time).locale(this.locale).format('LT')} Uhr`;

        // adds the measurement date to the label
        if (timeRange !== 'day') {
          text = `${dayjs(result.device_time).locale(this.locale).format('L')}, ` + text;
        }

        labels.push({
          text,
          documentId: result.document_id,
        });
      });

      // Toggles legends for weekly time-range
      const showLegend = timeRange === 'week' && index % 2 === 0 ? false : true;

      return {
        showItem: resultsInItem.length > 0,
        showLegend,
        title: this.extractRange(item.from, item.to),
        legend: this.extractLegend(timeRange, item.to),
        labels,
        graphPoints: [],
        from: item.from,
        to: item.to,
      };
    });
  }

  /**
   * Calculates and assigns placeholder-values for empty items in order to draw a clean graph
   * @param pageItems Array of page-items to calculate and assign placeholder values
   */
  assignPlaceholderValue(pageItems: ChartPageItem[]) {
    let gapStartsIndex: number = null;
    let lastValidIndex = 0;
    let gapStartsValue: GraphPoint[] = [];

    // Checks if first entry has value. Assigns average if not
    if (pageItems.length > 0) {
      if (pageItems[0].graphPoints.length === 0) {
        pageItems[0].graphPoints = this.resultsSummary;
      }
    }
    pageItems.forEach((pageItem, itemIndex) => {
      // Mark start-index of gap
      if (pageItem.graphPoints.length === 0) {
        if (gapStartsIndex === null) {
          gapStartsIndex = itemIndex;
        }
        // Last item has no value. Assigns last existing value to all items without value at the and of the list
        if (itemIndex === pageItems.length - 1) {
          for (let i = itemIndex; i > lastValidIndex; i--) {
            pageItems[i] = {
              ...pageItems[i],
              graphPoints: gapStartsValue,
            };
          }
        }
      } else {
        lastValidIndex = itemIndex;
        // First value after a gap -> calculate filling-values
        if (gapStartsIndex !== null) {
          const gapLength = itemIndex - gapStartsIndex;
          const gapDelta = pageItem.graphPoints.map(
            (point, i) => point.avg - gapStartsValue[i].avg,
          );
          const steps = gapDelta.map((delta) => delta / (gapLength + 1));

          // Assigns filling values
          for (let j = 0; j < gapLength; j++) {
            const newVal = gapStartsValue
              .map((startValue, i) => startValue.avg + (j + 1) * steps[i])
              .map((newValue) => ({ min: newValue, max: newValue, avg: newValue }));

            pageItems[gapStartsIndex + j] = {
              ...pageItems[gapStartsIndex + j],
              graphPoints: newVal,
            };
          }
          gapStartsIndex = null;
        }
        gapStartsValue = pageItem.graphPoints;
      }
    });
  }

  /**
   * To have a gapless graph between single pages, calculate values for the left and right side of a page
   * @param pageItems Arrays of page-items belonging to a single page
   */
  completeLineChartPages(pageItems: ChartPageItem[][]): ChartPage[] {
    return pageItems.map((page, index) => {
      // notice: pageItems are sorted from latest to oldest
      const prevPage = pageItems[index + 1];
      const nextPage = pageItems[index - 1];
      let leftBorderValue = null;
      let rightBorderValue = null;

      if (prevPage) {
        const lastPreviousValue = prevPage[prevPage.length - 1].graphPoints;
        const currentFirstValue = page[0].graphPoints;

        leftBorderValue = currentFirstValue.map(
          (currentValue, i) => (currentValue.avg + lastPreviousValue[i].avg) / 2,
        );
      }

      if (nextPage) {
        const firstNextValue = nextPage[0].graphPoints;
        const currentLastValue = page[page.length - 1].graphPoints;
        rightBorderValue = currentLastValue.map(
          (currentValue, i) => (currentValue.avg + firstNextValue[i].avg) / 2,
        );
      }

      return {
        pageNumber: index + 1,
        leftBorderValue,
        rightBorderValue,
        items: page,
        from: page[0].from,
        to: page[page.length - 1].to,
      };
    });
  }

  /**
   * Creates a list of labels for every measurement-result
   * @param results results to create labels from
   */
  createListedLabels(
    results: (
      | ObservationResult
      | ObservationResultWeight
      | ObservationResultBloodPressure
      | ObservationResultEcg
      | ObservationResultSurvey
    )[],
  ) {
    const labels: string[] = [];
    const unit = this.chartData.monitoringGoal.result_unit;
    const type = this.chartData.monitoringGoal.type;

    if (results.length) {
      orderBy(results, 'device_time', 'desc').map(
        (
          result:
            | ObservationResult
            | ObservationResultWeight
            | ObservationResultBloodPressure
            | ObservationResultEcg
            | ObservationResultSurvey,
        ) => {
          if (result.type !== 'ecg') {
            let label = '';

            if (isOfType<ObservationResultBloodPressure>(result, 'systolic')) {
              label = `${this.valueFormatPipe.transform(
                result.systolic,
                type,
              )} : ${this.valueFormatPipe.transform(result.diastolic, type)} ${unit}`;
            } else if (!isOfType<ObservationResultSurvey>(result, 'survey_id')) {
              label = `${this.valueFormatPipe.transform(result.value, type)} ${unit}`;
            }

            label += $localize` | ${dayjs(result.device_time)
              .locale(this.locale)
              .format('LT')} Uhr`;
            labels.push(label);
          }
        },
      );
    }
    return labels;
  }

  /**
   * Creates a label based on the min-/max-value of a point
   * @param graphPoint results to create labels from
   */
  createMergedLabels(graphPoint: GraphPoint[]) {
    const labels: string[] = [];
    const unit = this.chartData.monitoringGoal.result_unit;
    const type = this.chartData.monitoringGoal.type;
    let label = '';

    if (graphPoint.length) {
      if (type === 'BloodPressureGoal') {
        if (graphPoint.length === 2) {
          [$localize`systolisch: `, $localize`diastolisch: `].map((bpValueType, i) => {
            const point = graphPoint[i];

            label =
              point.min === point.max
                ? `${this.valueFormatPipe.transform(point.max, type)} ${unit}`
                : `${this.valueFormatPipe.transform(
                    point.min,
                    type,
                  )} - ${this.valueFormatPipe.transform(point.max, type)} ${unit}`;

            labels.push(`${bpValueType}: ${label}`);
          });
        }
      } else {
        const point = graphPoint[0];

        label =
          point.min === point.max
            ? `${this.valueFormatPipe.transform(point.max, type)} ${unit}`
            : `${this.valueFormatPipe.transform(
                point.min,
                type,
              )} - ${this.valueFormatPipe.transform(point.max, type)} ${unit}`;

        labels.push(label);
      }
    }
    return labels;
  }

  /**
   * Selects the current pages to draw and assigns it to the chart-component
   */
  selectPagesToDraw() {
    // Draws 20 pages in advance. 5 next pages (if available), 15 previous pages
    const startIndex = this.currentPage - 6 < 0 ? 0 : this.currentPage - 6;
    this.pagesToDraw = [
      ...this.chartPages[this.selectedItemRange].slice(startIndex, startIndex + 20),
    ];
  }

  /**
   * Changes the current time-range, if it's not already the current one
   * @param range New time-range to show
   */
  changeTimeRange(range: GraphItemRange) {
    if (this.selectedItemRange === range || !this.chartPages) {
      return;
    }

    // Sets corresponding new page
    const lastDateOnPage = this.chartPages[this.selectedItemRange][this.currentPage - 1].to;
    this.currentPage = this.getPageByDate(range, lastDateOnPage);
    this.selectedItemRange = range;

    this.updateNavigation();
    this.selectPagesToDraw();
  }

  /**
   * Invokes a chart scroll, sets new pages to draw and updates navigation-buttons
   * @param direction to navigate to
   */
  navigateChart(direction: 'prev' | 'next') {
    if (
      (direction === 'prev' && this.showPrev === false) ||
      (direction === 'next' && this.showNext === false)
    ) {
      return;
    }

    this.currentPage = direction === 'prev' ? this.currentPage + 1 : this.currentPage - 1;

    this.updateNavigation();
    this.selectPagesToDraw();
  }

  /**
   * Shows/hides navigation buttons
   * Updates currently displayed date-range based on the current page
   * Updates total pages
   */
  updateNavigation() {
    this.showNext = this.currentPage === 1 ? false : true;
    this.showPrev =
      this.currentPage === this.chartPages[this.selectedItemRange].length ? false : true;

    const currentChartPage = this.chartPages[this.selectedItemRange][this.currentPage - 1];
    this.displayedRange = this.extractRange(currentChartPage.from, currentChartPage.to);
    this.totalPages = this.chartPages[this.selectedItemRange].length;

    this.checkReload();
  }

  /**
   * Gets currently displayed date and emits a reload
   */
  checkReload() {
    if (!this.chartPages) return;

    const firstDateOnPage = this.chartPages[this.selectedItemRange][this.currentPage - 1].from;
    const threshold = this.timeRangeSettings[this.selectedItemRange].reloadThreshold;

    this.emitReload.emit(
      dayjs(firstDateOnPage, 'YYYY-MM-DD').subtract(threshold, 'week').format('YYYY-MM-DD'),
    );
  }

  /**
   * Returns the page number of a page, which includes a given date
   *
   * @param range time-range to search in
   * @param date to search for
   * @returns corresponding page-number in range
   */
  getPageByDate(range: GraphItemRange, date: string) {
    return this.chartPages[range]
      .filter((page) => dayjs(date).isBetween(page.from, page.to, 'day', '[]') === true)
      .map((page) => page.pageNumber)[0];
  }

  /**
   * Invokes a router navigations to the target document id
   * @param documentId number document-id to open
   */
  navigateToDocument(documentId: number) {
    this.router.navigate(['app/document-detail', documentId]);
  }

  /**
   * Splits an array into chunks of given size
   */
  chunkArray<T>(arr: T[], chunkSize: number): T[][] {
    const chunkedArr = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArr.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArr.reverse();
  }

  /**
   * Validates a date-string against a format string
   *
   * @param date date-string e.g. '24-08-2018
   * @param format format-string to check against e.g. 'YYYY-MM-DD'
   */
  validateDateString(date: string, format: string) {
    return !date || !format ? false : dayjs(date, format).format(format) === date;
  }

  /**
   * Calculates min, max and average values over an array of results
   * @param results array of measurement-results
   * @returns merged results as graph-point (min, max, avg)
   */
  getMinMaxAvgFromMeasurements(
    results: (
      | ObservationResult
      | ObservationResultWeight
      | ObservationResultBloodPressure
      | ObservationResultEcg
      | ObservationResultSurvey
    )[],
  ): GraphPoint[] {
    const resultValues: number[][] = [[]];

    if (results.length) {
      results.map((result) => {
        if (result.type !== 'ecg') {
          if (isOfType<ObservationResultBloodPressure>(result, 'systolic')) {
            if (!resultValues[1]) {
              resultValues.push([]);
            }
            resultValues[0].push(result.systolic);
            resultValues[1].push(result.diastolic);
          } else if (!isOfType<ObservationResultSurvey>(result, 'survey_id')) {
            resultValues[0].push(result.value);
          }
        }
      });

      return resultValues.map((value) => ({
        min: Math.min(...value),
        max: Math.max(...value),
        avg: value.length > 0 ? loadashSum(value) / value.length : 0,
      }));
    }
    return [];
  }

  /**
   * Receives two date-keys in YYYY-MM-DD format and creates a readable range-string (from - to)
   *
   * @param start 'YYYY-MM-DD'-string
   * @param end 'YYYY-MM-DD'-string
   */
  extractRange(start: string, end: string) {
    if (
      this.validateDateString(start, this.dayFormat) === false ||
      this.validateDateString(end, this.dayFormat) === false
    ) {
      return null;
    }

    const parts = {
      from: {
        d: dayjs(start).format('DD'),
        m: dayjs(start).locale(this.locale).format('MMM').toUpperCase(),
        y: dayjs(start).format('YYYY'),
      },
      to: {
        d: dayjs(end).format('DD'),
        m: dayjs(end).locale(this.locale).format('MMM').toUpperCase(),
        y: dayjs(end).format('YYYY'),
      },
    };

    if (start === end) {
      return `${parts.from.d}. ${parts.from.m} ${parts.from.y}`;
    }

    if (parts.from.y !== parts.to.y) {
      return `${parts.from.d}. ${parts.from.m} ${parts.from.y} - ${parts.to.d}. ${parts.to.m} ${parts.to.y}`;
    } else {
      if (parts.from.m !== parts.to.m) {
        return `${parts.from.d}. ${parts.from.m} - ${parts.to.d}. ${parts.to.m} ${parts.to.y}`;
      } else {
        return `${parts.from.d}. - ${parts.to.d}. ${parts.to.m} ${parts.to.y}`;
      }
    }
  }

  /**
   * Takes a date-key and extracts the legend-label, based on the given range
   *
   * @param range range-type day | week | month
   * @param dateKey date-key to extract legend from
   */
  extractLegend(range: GraphItemRange, dateKey: string): string {
    return range === 'day'
      ? dayjs(dateKey).locale(this.locale).format('dd').toUpperCase()
      : dayjs(dateKey).locale(this.locale).format('MMM').toUpperCase();
  }

  /**
   * Creates item-stubs, containing first and last date in item, for every
   * possible page-item (column) over all time-ranges
   */
  createPageItemsBoundaries() {
    this.pageItemBoundaries = {
      week: [],
      month: [],
      day: [],
    };
    const start = dayjs(this.globalBoundaries.from, this.dayFormat);
    const end = dayjs(this.globalBoundaries.to, this.dayFormat);

    const dayPagesTotal = end.diff(start, 'week') + 1;
    const weekPagesTotal = Math.ceil(dayPagesTotal / this.timeRangeSettings.week.itemsPerPage); // 12 weeks per page
    const monthDiffs = end.diff(start, 'month') || 1; // at least 1 month must be defined
    const monthPagesTotal = Math.ceil(monthDiffs / this.timeRangeSettings.month.itemsPerPage);

    // Pages for one-week range
    for (let i = dayPagesTotal * this.timeRangeSettings.day.itemsPerPage - 1; i >= 0; i--) {
      const itemDate = end.subtract(i, 'day').format(this.dayFormat);

      this.pageItemBoundaries.day.push({
        from: itemDate,
        to: itemDate,
      });
    }

    // Pages for 3-month range
    for (let i = weekPagesTotal * this.timeRangeSettings.week.itemsPerPage; i > 0; i--) {
      const itemStartDate = end.subtract(i, 'week').add(1, 'day');
      const itemEndDate = itemStartDate.add(6, 'day');

      this.pageItemBoundaries.week.push({
        from: itemStartDate.format(this.dayFormat),
        to: itemEndDate.format(this.dayFormat),
      });
    }

    // Pages for one-year range
    for (let i = monthPagesTotal * this.timeRangeSettings.month.itemsPerPage; i > 0; i--) {
      const itemStartDate = end.subtract(i, 'month').add(1, 'month').startOf('month');
      let itemEndDate = itemStartDate.endOf('month');

      // Last displayed date is the current day
      if (itemEndDate.isAfter(end, 'day') === true) {
        itemEndDate = dayjs(end);
      }

      this.pageItemBoundaries.month.push({
        from: itemStartDate.format(this.dayFormat),
        to: itemEndDate.format(this.dayFormat),
      });
    }
  }
}
