import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageFacade } from 'storage-store-facade/storage.facade';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of, from, timer } from 'rxjs';
import {
  map,
  catchError,
  switchMap,
  tap,
  withLatestFrom,
  takeWhile,
  takeUntil,
} from 'rxjs/operators';
import { SignInData } from 'app/models';
import { EnvironmentService } from 'app/services/environment.service';
import { ApiService } from 'store/api/api.service';
import * as AuthActions from './auth.actions';
import { BiometricService } from 'app/services/biometric.service';
import { ActionSheetController, Platform } from '@ionic/angular';
import * as fromAuth from 'store/auth-store/auth.reducer';
import * as AppActions from 'store/app.actions';
import * as DeviceActions from 'store/devices-store/devices.actions';

@Injectable()
export class AuthEffects implements OnInitEffects {
  private readonly INACTIVITY_TIMEOUT = 15 * 60 * 1000;
  private readonly INACTIVITY_ACTION_SHEET_TIMEOUT = 2 * 60 * 1000;
  private INACTIVITY_ACTION_SHEET: HTMLIonActionSheetElement;

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private storage: StorageFacade,
    private router: Router,
    private biometric: BiometricService,
    private envService: EnvironmentService,
    private actionSheetController: ActionSheetController,
    private store: Store<fromAuth.State>,
    private platform: Platform,
  ) {}

  authenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authenticate),
      switchMap((action) =>
        this.apiService.signIn({ email: action.email, password: action.password }).pipe(
          map((signInresponse) => {
            // throw an error, if authorization-header is missing
            try {
              if (!signInresponse.headers.has('authorization')) {
                return AuthActions.authenticateTokenFailure({
                  error: 'Missing Header Error',
                });
              }
            } catch (err) {
              return AuthActions.authenticateTokenFailure({ error: 'Missing Header Error' });
            }
            // aks user to use biometric login
            this.biometric.confirmStoreBiometrics(action.email, action.password);

            return AuthActions.authenticateSuccess({
              data: { ...signInresponse.body.data, surname: '', firstname: '', email: '' },
              navigation: action.navigation,
            });
          }),
          catchError((error) => of(AuthActions.authenticateFailure({ error }))),
        ),
      ),
    );
  });

  setStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authenticateSuccess),
      switchMap((action) =>
        from(
          this.storage.set('signInData', { ...action.data, surname: '', firstname: '', email: '' }),
        ).pipe(
          map(() =>
            AuthActions.setStorageSuccess({
              navigation: action.navigation,
            }),
          ),
        ),
      ),
    );
  });

  redirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthActions.setStorageSuccess,
          AuthActions.removeStorageSuccess,
          AuthActions.clearStateSuccess,
          AuthActions.deleteAuthStorageSuccess,
        ),
        tap((action) => {
          this.router.navigate(action.navigation.commands, action.navigation.extras);
        }),
      );
    },
    { dispatch: false },
  );

  addSignInData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.addSignInData),
      switchMap(() =>
        from(this.storage.get('signInData')).pipe(
          map((signInData: SignInData) =>
            signInData
              ? AuthActions.addSignInDataSuccess({ data: signInData })
              : AuthActions.addSignInDataFailure(),
          ),
        ),
      ),
    );
  });

  signOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signOut),
      switchMap((action) =>
        this.apiService.signOut().pipe(
          map(() => AuthActions.signOutSuccess({ navigation: action.navigation })),
          catchError((error) => of(AuthActions.signOutFailure({ error }))),
        ),
      ),
    );
  });

  deleteAuthStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.deleteAuthStorage),
      switchMap(() =>
        from(this.storage.get('auth')).pipe(
          map((d) =>
            d
              ? AuthActions.signOutSuccess({
                  navigation: {
                    commands: ['login'],
                    extras: { queryParams: { openBiometrics: 'true' } },
                  },
                })
              : AuthActions.deleteAuthStorageSuccess({ navigation: { commands: ['welcome'] } }),
          ),
        ),
      ),
    );
  });

  deleteUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.deleteUser),
      switchMap((action) =>
        this.apiService.deleteUser(action.exportData).pipe(
          map(() => AuthActions.deleteUserSuccess({ navigation: action.navigation })),
          catchError((error) => of(AuthActions.deleteUserFailure({ error }))),
        ),
      ),
    );
  });

  removeStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signOutSuccess, AuthActions.deleteUserSuccess, AuthActions.clearState),
      switchMap((action) =>
        this.getRemoveStorageRequest(action).pipe(
          map(() =>
            action.type !== AuthActions.clearState.type
              ? AuthActions.removeStorageSuccess({
                  navigation: action.navigation,
                })
              : AuthActions.clearStateSuccess({ navigation: action.navigation }),
          ),
        ),
      ),
    );
  });

  setUserId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.addSignInDataSuccess, AuthActions.setStorageSuccess),
      switchMap(() =>
        from(this.storage.get('signInData')).pipe(
          map((d: SignInData) => {
            if (d?.id) {
              localStorage.setItem('pro_userId', `${d.id}`);
              return AuthActions.addUserIdSuccess();
            } else {
              // if user id does not exist, log out to force user to login again
              return AuthActions.signOut({ navigation: { commands: ['/login'] } });
            }
          }),
        ),
      ),
    );
  });

  resetInactivityTimer$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthActions.addUserIdSuccess,
          AuthActions.resetInactivityTimer,
          AuthActions.deleteAuthStorage,
          DeviceActions.startScan,
          DeviceActions.loadMeasurementsSuccess,
        ),
        switchMap(() => {
          return timer(this.INACTIVITY_TIMEOUT, this.INACTIVITY_TIMEOUT).pipe(
            withLatestFrom(this.store.pipe(select(fromAuth.selectUserId))),
            takeWhile(([, userId]) => userId !== null), // unsubscribe if user was logged out
            takeUntil(this.platform.pause), // unsubscribe if app is in background
            map(() => {
              this.openActionSheet();
            }),
          );
        }),
      );
    },
    { dispatch: false },
  );

  ngrxOnInitEffects(): any {
    return this.envService.environment.reAuthentication
      ? AuthActions.deleteAuthStorage()
      : AuthActions.addSignInData();
  }

  private getRemoveStorageRequest(action: Action) {
    if (
      action.type === AuthActions.signOutSuccess.type ||
      action.type === AuthActions.clearState.type
    ) {
      this.storage.set('signInData', null);
      return from(this.storage.set('auth', null));
    }
    return from(this.storage.clear());
  }

  private async openActionSheet() {
    await this.INACTIVITY_ACTION_SHEET?.dismiss();

    this.INACTIVITY_ACTION_SHEET = await this.actionSheetController.create({
      header: $localize`Bist Du noch da? Um Deine Sicherheit zu gewährleisten, melden wir Dich ab, wenn keine weitere Aktivität erkannt wird.`,
      buttons: [
        {
          text: $localize`Ich bin noch da`,
          handler: () => {
            this.store.dispatch(AuthActions.resetInactivityTimer());
          },
        },
        {
          text: $localize`Abmelden`,
          handler: () => {
            this.inactivitySignOut();
          },
        },
      ],
    });

    await this.INACTIVITY_ACTION_SHEET.present();

    const actionSheetTimer = setTimeout(() => {
      this.inactivitySignOut(true);
      this.INACTIVITY_ACTION_SHEET?.dismiss();
    }, this.INACTIVITY_ACTION_SHEET_TIMEOUT);

    this.INACTIVITY_ACTION_SHEET.onWillDismiss().then(() => {
      if (actionSheetTimer) {
        clearTimeout(actionSheetTimer);
      }
      this.INACTIVITY_ACTION_SHEET = null;
    });
  }

  private inactivitySignOut(showToast = false) {
    this.store.dispatch(
      AuthActions.signOut({
        navigation: { commands: ['/login'] },
      }),
    );

    if (showToast) {
      this.store.dispatch(
        AppActions.showErrorMessage(
          $localize`Du wurdest aus Sicherheitsgründen nach längerer Inaktivität abgemeldet.`,
          $localize`Bitte melde Dich erneut an.`,
        ),
      );
    }
  }
}
