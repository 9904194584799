import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SUBSCRIPTIONS_ACTIVIATION_SUCCESS_COMBINED_PATH } from 'app/shared/shared.routes';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import * as InfoActions from './info.actions';
import * as fromInfo from './info.reducer';

@Injectable()
export class InfoEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private toastController: ToastController,
    private router: Router,
    private store: Store<fromInfo.State>
  ) {}

  loadInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InfoActions.loadInfo),
      mergeMap(() =>
        this.apiService.getInfos().pipe(
          map((data) => InfoActions.loadInfoSuccess({ data })),
          catchError((error) => of(InfoActions.loadInfoFailure({ error })))
        )
      )
    );
  });

  updateInfoStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InfoActions.updateInfo),
      mergeMap((action) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(fromInfo.selectInfo))))
      ),
      map(([action, data]) =>
        InfoActions.updateInfoPartialSuccess({ data, updatedData: action.data })
      )
    );
  });

  updateInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InfoActions.updateInfoPartialSuccess),
      switchMap((action) =>
        this.apiService.updateInfos(action.updatedData).pipe(
          map(() => InfoActions.updateInfoSuccess()),
          catchError(() =>
            of(
              InfoActions.updateInfoFailure({
                data: action.data,
              })
            )
          )
        )
      )
    );
  });

  updateTmcMandatoryInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InfoActions.updateTmcMandatoryInfo),
      switchMap((action) =>
        this.apiService.updateInfos(action.data).pipe(
          map((data) => InfoActions.updateTmcMandatoryInfoSuccess({ data })),
          catchError((error: HttpErrorResponse) => {
            const errorMessage = error.error?.errorMessage
              ? error.error.errorMessage
              : $localize`Fehler beim Speichern der Daten. Bitte versuche es nochmal.`;
            return of(
              InfoActions.updateTmcMandatoryInfoFailure({
                errorMessage,
              })
            );
          })
        )
      )
    );
  });

  updateTmcMandatorySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(InfoActions.updateTmcMandatoryInfoSuccess),
        tap(() => {
          this.router.navigateByUrl(SUBSCRIPTIONS_ACTIVIATION_SUCCESS_COMBINED_PATH);
        })
      );
    },
    { dispatch: false }
  );

  // uncomment if error message should be handled separately
  //   updateTmcMandatoryFailure$ = createEffect(
  //     () => {
  //       return this.actions$.pipe(
  //         ofType(InfoActions.updateTmcMandatoryInfoFailure),
  //         tap((action) => [
  //           this.toastController
  //             .create({
  //               header: action.errorMessage,
  //               position: 'top',
  //               color: 'warning',
  //               buttons: [
  //                 {
  //                   icon: 'close',
  //                   role: 'cancel',
  //                 },
  //               ],
  //             })
  //             .then((toast) => {
  //               toast.present();
  //             }),
  //         ])
  //       );
  //     },
  //     { dispatch: false }
  //   );
}
