import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, filter, concatMap } from 'rxjs/operators';
import { AuthFacade } from 'app/app-store/auth-store/auth.facade';
import { TermsOfServiceFacade } from 'app/app-store/terms-of-service-store/terms-of-service.facade';

@Injectable({
  providedIn: 'root',
})
export class TermsOfServiceGuard {
  constructor(
    private authFacade: AuthFacade,
    private termsOfServiceFacade: TermsOfServiceFacade,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.activateGuard(state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.activateGuard(state);
  }

  private activateGuard(state: RouterStateSnapshot) {
    return this.termsOfServiceFacade.termsOfServiceAdding$.pipe(
      filter((a) => !a),
      concatMap(() => this.termsOfServiceFacade.termsOfService$),
      concatMap((tos) =>
        // additional tos check in case user has no tos storage yet
        // prevents users from unnecessary redirect to legal page
        tos
          ? of(tos)
          : this.authFacade.acceptedTermsOfService$.pipe(
              map((accepted) => ({
                accepted,
              })),
            ),
      ),
      map((tos) =>
        !tos
          ? this.router.createUrlTree(['app/legal'], { queryParams: { url: state.url } })
          : !tos.accepted
            ? this.router.createUrlTree(['app/legal'], { queryParams: { url: state.url } })
            : tos.accepted,
      ),
    );
  }
}
