import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ContentChild,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { IonInput, IonItem, IonSelect } from '@ionic/angular';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatePickerItemControlComponent } from '../date-picker-item-control/date-picker-item-control.component';
import { formErrors } from './validation';

export const ERRORS = new InjectionToken('ERRORS', {
  providedIn: 'root',
  factory: () => formErrors,
});

@Component({
  template: `<div class="error">
    <span #errorMessage>{{ text }}</span>
  </div>`,
  styles: [
    `
      .error {
        min-height: 24px;
        display: block;
        font-size: 0.82rem;
        line-height: 0.97rem;
        font-weight: bold;
        color: var(--ion-color-warning);
        padding-right: 24px;
      }

      .error > * {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlErrorComponent {
  private TEXT: string;

  @ViewChild('errorMessage', { read: ElementRef }) errorMessage: ElementRef;
  @Input()
  set text(value) {
    if (value !== this.TEXT) {
      this.TEXT = value;
      this.cdr.detectChanges();
      this.renderer.removeClass(this.errorMessage.nativeElement, 'show-message');
    }
  }

  get text() {
    return this.TEXT;
  }

  constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2) {}
}

@Directive({
  selector: '[proControlErrorMessage]',
})
export class ControlErrorMessageDirective {
  constructor(public vcr: ViewContainerRef) {}
}

@Directive({
  selector: '[proControlError]',
})
export class ControlErrorDirective implements OnInit, OnDestroy {
  private FORM_ELEMENT: IonSelect | IonInput | DatePickerItemControlComponent = undefined;
  ref: ComponentRef<ControlErrorComponent>;
  ngUnsubscribe$: Subject<void> = new Subject();

  @Input('proControlError') set formElement(
    value: IonSelect | IonInput | DatePickerItemControlComponent
  ) {
    // IonSelect
    if (value instanceof IonSelect) {
      this.FORM_ELEMENT = value;
      value.ionCancel
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(() => this.toggleError(this.control.errors));
    }

    // DatePickerItemControlComponent
    if (value instanceof DatePickerItemControlComponent) {
      this.FORM_ELEMENT = value;
      value.ionCancel
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(() => this.toggleError(this.control.errors));
    }
  }
  @ContentChild(ControlErrorMessageDirective, { static: true })
  controlErrorMessage: ControlErrorMessageDirective;

  constructor(
    @Self() private control: NgControl,
    @Inject(ERRORS) private errors: any,
    private renderer: Renderer2,
    private element: ElementRef,
    private vcr: ViewContainerRef
  ) {}

  @HostListener('focusout') onBlur() {
    // show error message on IonInput element
    if (this.FORM_ELEMENT === undefined || this.FORM_ELEMENT instanceof IonItem) {
      this.toggleError(this.control.errors);
    }
  }

  ngOnInit() {
    this.createErrorComponent(null);
    merge(this.control.valueChanges)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.toggleError(this.control.errors);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public update() {
    this.toggleError(this.control.errors);
  }

  private createErrorComponent(text: string) {
    if (!this.ref) {
      this.ref = !this.controlErrorMessage
        ? this.vcr.createComponent(ControlErrorComponent)
        : this.controlErrorMessage.vcr.createComponent(ControlErrorComponent);
    }
    this.ref.instance.text = text;
  }

  private toggleError(errors: ValidationErrors) {
    if (errors) {
      const errorKey = Object.keys(errors)[0]; // get first error key
      const getError = this.errors[errorKey];
      const text: string = getError(errors[errorKey]);
      this.toggleErrorLabel('warning');
      this.createErrorComponent(text);
    } else {
      this.toggleErrorLabel('');
      this.createErrorComponent(null);
    }
  }

  private toggleErrorLabel(color: 'warning' | '') {
    const label = this.element.nativeElement.querySelector('ion-label');
    if (!(this.FORM_ELEMENT instanceof DatePickerItemControlComponent)) {
      // change IonInput, IonSelect label color
      this.renderer.setAttribute(
        this.renderer.parentNode(this.element.nativeElement).firstChild,
        'color',
        color
      );
    } else if (label) {
      // change DatePickerItemControl label color
      this.renderer.setAttribute(label, 'color', color);
    }
  }
}

@Component({
  template: `<div class="form-error-message" [@showError]="visibleStatus ? 'visible' : 'hidden'">
    <span>{{ text }}</span>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showError', [
      state(
        'hidden',
        style({
          height: '0',
          transform: 'translateY(0px)',
          opacity: '0',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          transform: 'translateY(4px)',
          opacity: '1',
        })
      ),
      transition('hidden => visible', animate('300ms ease-out')),
      transition('visible => hidden', animate('300ms ease-in')),
    ]),
  ],
})
export class FormErrorComponent {
  private TEXT: string;
  private VISIBLE: boolean;

  @Input()
  set text(value) {
    if (value !== this.TEXT) {
      this.TEXT = value;
      this.cdr.detectChanges();
    }
  }

  get text() {
    return this.TEXT;
  }

  @Input()
  set visibleStatus(value) {
    this.VISIBLE = value;
    this.cdr.detectChanges();
  }

  get visibleStatus() {
    return this.VISIBLE;
  }

  constructor(private cdr: ChangeDetectorRef) {}
}

@Directive({
  selector: '[proFormError]',
})
export class FormErrorDirective implements OnInit, OnDestroy {
  ref: ComponentRef<FormErrorComponent>;
  ngUnsubscribe$: Subject<void> = new Subject();
  errorElement: ElementRef;
  control: NgControl;

  @Input() linkedControl: NgControl = undefined;

  constructor(
    @Self() @Optional() private baseControl: NgControl,
    @Inject(ERRORS) private errors: any,
    private vcr: ViewContainerRef
  ) {}

  ngOnInit() {
    this.control = this.baseControl || this.linkedControl;
    this.createErrorComponent(null);

    merge(this.control?.statusChanges)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.toggleError(this.control.errors);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private toggleError(errors: ValidationErrors) {
    if (errors) {
      const errorKey = Object.keys(errors)[0]; // get first error key
      const getError = this.errors[errorKey];
      const text: string = getError(errors[errorKey]);
      this.createErrorComponent(text);
      this.ref.instance.visibleStatus = true;
    } else {
      this.ref.instance.visibleStatus = false;
    }
  }

  private createErrorComponent(text: string) {
    if (!this.ref) {
      this.ref = this.vcr.createComponent(FormErrorComponent);
      this.errorElement = this.ref.location.nativeElement.querySelector(`.form-error-message`);
    }

    if (text) {
      this.ref.instance.text = text;
    }
  }
}
