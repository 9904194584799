<ion-content>
  <p>{{ textContent }}</p>

  <form *ngIf="form" [formGroup]="form">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-input
            label="Passwort"
            labelPlacement="floating"
            [type]="hidePassword === true ? 'password' : 'text'"
            formControlName="password"
            inputmode="text"
            autocomplete="password"
            enterkeyhint="done"
            (keydown)="errorMessage = ''"
            (keyup.enter)="submit()"
            proFormError
            proTrimOnBlur
            #pwInput
            i18n-label
          >
            <ion-icon
              (click)="togglePassword()"
              [name]="hidePassword === false ? 'eye-off-outline' : 'eye-outline'"
              class="toggle-password"
              data-cy="toggle-pw"
              role="button"
              aria-label="{hidePassword, select, false {Passwort ausblenden} true {Passwort anzeigen}}"
              i18n-aria-label
            ></ion-icon>
          </ion-input>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="errorMessage !== ''">
        <ion-col
          ><span class="form-error-message">{{ errorMessage }}</span></ion-col
        >
      </ion-row>
    </ion-grid>
  </form>

  <ion-button
    (click)="submit()"
    fill="solid"
    expand="block"
    [ngClass]="{ disabled: form.invalid }"
    [disabled]="loading || (isOffline$ | async)"
    data-cy="send-pw-btn"
    aria-label="Absenden"
    i18n-aria-label
  >
    <span *ngIf="!loading" i18n>Absenden</span>
    <ion-spinner *ngIf="loading" name="dots"></ion-spinner>
  </ion-button>

  <ion-button
    (click)="dismissModal()"
    expand="block"
    fill="clear"
    role="button"
    aria-label="Abbrechen"
    i18n-aria-label
    i18n
  >
    Abbrechen
  </ion-button>
</ion-content>
