import { Injectable, NgZone } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { AliveCor, AliveCorPluginResponse, StartPrepareToRecordEvent } from 'alive-cor';
import { ToastController, ModalController, PopoverController } from '@ionic/angular';
import { Subject, BehaviorSubject, Subscription, of, combineLatest } from 'rxjs';
import { filter, withLatestFrom, map, concatMap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import * as fromDevices from 'store/devices-store/devices.reducer';
import * as DevicesActions from 'store/devices-store/devices.actions';
import { enterZone, isOfType } from 'app/utils';
import { UploadRequest, DeviceInformation, ResultValue, ResultWeight } from 'app/models';
import { EnvironmentService } from 'app/services/environment.service';
import * as AppActions from 'store/app.actions';
import * as Bm54Actions from 'store/devices-store/bm-store/bm54-store/bm54.actions';
import * as Bm57Actions from 'store/devices-store/bm-store/bm57-store/bm57.actions';
import * as Bm64Actions from 'store/devices-store/bm-store/bm64-store/bm64.actions';
import * as Po60Actions from 'store/devices-store/po60-store/po60.actions';
import * as Bf720Actions from 'store/devices-store/bf720-store/bf720.actions';
import * as fromBf720 from 'store/devices-store/bf720-store/bf720.reducer';
import * as fromUploadRequests from 'store/documents-store/upload-request.reducer';
import * as UploadRequestActions from 'store/documents-store/upload-request.actions';
import {
  BmResultBloodPressure,
  DeviceType,
  Po60ResultValues,
} from 'store/devices-store/devices.model';
import { BluetoothHelperService } from './bluetooth-helper.service';
import { BluetoothPermissionInfoComponent } from './bluetooth-permission-info/bluetooth-permission-info.component';
import { SdkAboutScreenComponent } from './sdk-about-screen/sdk-about-screen.component';

@Injectable({
  providedIn: 'root',
})
export class DevicesFacade {
  private START_PREPARE_TO_RECORD_EVENT_LISTENER: Promise<PluginListenerHandle>;
  private FINISHED_RECORD_EVENT_LISTENER: Promise<PluginListenerHandle>;
  private RECORD_RESULT_EVENT_LISTENER: PluginListenerHandle;
  private ECG_RECORDING_FINISHED$ = new Subject<boolean>();
  private ECG_UPLOADING$ = new BehaviorSubject(false);
  ecgRecordingFinished$ = this.ECG_RECORDING_FINISHED$.asObservable();
  ecgUploadRequest$ = this.store.pipe(select(fromUploadRequests.selectEcgUploadRequest));
  ecgUploading$ = this.ECG_UPLOADING$.asObservable().pipe(
    withLatestFrom(this.store.pipe(select(fromUploadRequests.selectEcgUploadRequest))),
    map(([reducing, document]) => reducing || document !== undefined),
  );
  devices$ = this.store.pipe(select(fromDevices.selectDevicesOnlyOnePerType));
  device$ = this.store.pipe(select(fromDevices.selectDeviceByName));
  devicesNotConnected$ = this.store.pipe(select(fromDevices.selectDevicesNotConnected));
  hasDevicesConnected$ = this.store.pipe(
    select(fromDevices.selectDevicesConnected),
    map((devices) => devices.length > 0),
  );
  hasAllDevicesConnected$ = this.store.pipe(select(fromDevices.selectAllDevicesConnected));
  bloodPressureDevice$ = this.store.pipe(select(fromDevices.selectBloodPressureDevice));
  userListBf720$ = this.store.pipe(enterZone(this.zone), select(fromBf720.selectUserList));
  userBf720$ = this.store.pipe(enterZone(this.zone), select(fromBf720.selectUser));
  userInitialsBf720$ = this.store.pipe(enterZone(this.zone), select(fromBf720.selectUserInitials));
  responseValueBf720$ = this.store.pipe(select(fromBf720.selectResponseValue));
  deviceLoading$ = this.store.pipe(enterZone(this.zone), select(fromBf720.selectLoading));
  deviceScanning$ = this.store.pipe(enterZone(this.zone), select(fromDevices.selectScanning));
  deviceScanError$ = this.store.pipe(enterZone(this.zone), select(fromDevices.selectScanError));
  deviceErrors$ = this.store.pipe(enterZone(this.zone), select(fromDevices.selectErrors));
  isLocationEnabledAndAvailable$ = this.bluetoothHelper.isLocationEnabledAndAvailable$;
  isBluetoothEnabled$ = this.bluetoothHelper.isBluetoothEnabled$;

  locationSettingsNeeded$ = combineLatest([
    this.hasDevicesConnected$,
    this.bluetoothHelper.isLocationEnabledAndAvailable$,
  ]).pipe(
    map(
      ([hasDevicesConnected, isLocationEnabledAndAvailable]) =>
        hasDevicesConnected === true && isLocationEnabledAndAvailable === false,
    ),
  );

  constructor(
    private store: Store<fromDevices.State | fromUploadRequests.State>,
    private zone: NgZone,
    private bluetoothHelper: BluetoothHelperService,
    private toastController: ToastController,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private environment: EnvironmentService,
  ) {}

  loadDevices() {
    this.store.dispatch(DevicesActions.loadDevices());
  }

  startScan(device: string) {
    this.store.dispatch(DevicesActions.startScan({ device }));
  }

  startScanManualMeasurements(device: string) {
    this.store.dispatch(DevicesActions.startScanManualMeasurements({ device }));
  }

  stopScan() {
    this.store.dispatch(DevicesActions.stopScan());
  }

  pauseScanning() {
    this.store.dispatch(DevicesActions.pauseScanning());
  }

  restartScanning() {
    this.store.dispatch(DevicesActions.restartScanning());
  }

  showConsentCode(userIndex: number) {
    this.store.dispatch(Bf720Actions.showConsentCode({ userIndex }));
  }

  createUser(code: number) {
    this.store.dispatch(Bf720Actions.createUser({ code }));
  }

  loginUser(userIndex: number, code: number) {
    this.store.dispatch(Bf720Actions.loginUser({ userIndex, code }));
  }

  deleteDevice(id: string) {
    this.store.dispatch(DevicesActions.deleteDevice({ id }));
  }

  getDevice(id: string) {
    return this.store.pipe(enterZone(this.zone), select(fromDevices.selectDevice({ id })));
  }

  getDevicesConnectedByType(type: DeviceType) {
    return this.store.pipe(select(fromDevices.selectDevicesConnectedByType({ type })));
  }

  updateLastResults(
    device: string,
    lastResults: BmResultBloodPressure[] | Po60ResultValues[] | ResultValue[],
  ) {
    if (device === 'BM54' && isOfType<BmResultBloodPressure[]>(lastResults, 'type')) {
      return this.store.dispatch(Bm54Actions.updateLastResults({ lastResults }));
    }

    if (device === 'BM57' && isOfType<BmResultBloodPressure[]>(lastResults, 'type')) {
      return this.store.dispatch(Bm57Actions.updateLastResults({ lastResults }));
    }

    if (device === 'BM64' && isOfType<BmResultBloodPressure[]>(lastResults, 'type')) {
      return this.store.dispatch(Bm64Actions.updateLastResults({ lastResults }));
    }

    if (device === 'PO60' && isOfType<Po60ResultValues[]>(lastResults, 'type')) {
      return this.store.dispatch(Po60Actions.updateLastResults({ lastResults }));
    }

    if (device === 'BF720' && isOfType<ResultWeight[]>(lastResults, 'type')) {
      return this.store.dispatch(Bf720Actions.updateLastResults({ lastResults }));
    }
  }

  checkLocationPermission() {
    return this.bluetoothHelper.checkAndroidLocationPermissions();
  }

  requestLocationPermission() {
    return this.bluetoothHelper.requestLocationPermission();
  }

  async startAliveCorRecording(
    patientMrn: string,
    firstname: string,
    surname: string,
    birthdate: string,
  ) {
    const hasPermissions = await this.hasBtPermissionsRequestBtPermissions().toPromise();

    if (hasPermissions) {
      (await this.START_PREPARE_TO_RECORD_EVENT_LISTENER)?.remove();
      (await this.FINISHED_RECORD_EVENT_LISTENER)?.remove;
      this.RECORD_RESULT_EVENT_LISTENER?.remove();
      this.addAliveCorEventListeners();
      return AliveCor.startRecording({
        ...this.environment.environment.kardiaCredentials,
        patientMrn,
        applicationName: 'ProHerz',
        patient: {
          firstname,
          surname,
          birthdate,
        },
      })
        .then((r: AliveCorPluginResponse): any => {
          this.uploadEcg(r);
        })
        .catch((error: any) => {
          this.modalController.dismiss();
          this.store.dispatch(AppActions.showErrorMessage());
          throw new Error(
            `Message: ${error.message ? error.message : error.errorMessage}. Code: ${error.code}.`,
          );
        });
    }
  }

  hasBtPermissionsRequestBtPermissions() {
    return this.bluetoothHelper.hasPermissionBtScanRequestPermissionBtScan().pipe(
      concatMap((r) =>
        r.hasPermission
          ? this.bluetoothHelper.hasPermissionBtConnectRequestPermissionBtConnect()
          : of(r),
      ),
      map((r) => {
        if (!r.hasPermission) {
          this.presentPopover();
        }
        return r.hasPermission;
      }),
    );
  }

  private addAliveCorEventListeners() {
    this.ECG_RECORDING_FINISHED$.next(false);

    // show sdkAboutScreen while initilising SDK
    this.START_PREPARE_TO_RECORD_EVENT_LISTENER = AliveCor.addListener(
      'startPrepareToRecordEvent',
      (r: StartPrepareToRecordEvent) => {
        if (r.startPrepareToRecordEvent) {
          this.openSdkAboutScreenModal();
        } else {
          this.modalController.dismiss();
          this.removeEventListener(this.START_PREPARE_TO_RECORD_EVENT_LISTENER);
        }
      },
    );

    // emit ectRecordingFinished$ true when the ecg was recorded
    this.FINISHED_RECORD_EVENT_LISTENER = AliveCor.addListener('finishedRecordEvent', () => {
      this.ECG_RECORDING_FINISHED$.next(true);
      this.removeEventListener(this.FINISHED_RECORD_EVENT_LISTENER);
    });
  }

  private async removeEventListener(eventListener: Promise<PluginListenerHandle>) {
    (await eventListener)?.remove();
  }

  private async openSdkAboutScreenModal() {
    const modal = await this.modalController.create({
      component: SdkAboutScreenComponent,
    });
    return await modal.present();
  }

  private async uploadEcg(response: AliveCorPluginResponse) {
    // show transferring toast while ecg result pdf is reduced and uploaded
    const transferringToast = await this.toastController.create({
      header: $localize`Dein EKG wird gespeichert.`,
      position: 'top',
      color: 'light',
      buttons: [
        {
          icon: 'close',
          role: 'close',
        },
      ],
    });
    transferringToast.present();

    // get deviceInformation
    const device = {
      manufacturer: 'AliveCor',
      model: response.deviceInformation.device,
      software_version: response.softwareVersion,
      hardware_version: response.deviceInformation.hardwareVersion,
      firmware_version: response.deviceInformation.firmwareVersion,
    };

    // get blob from ecg
    this.ECG_UPLOADING$.next(true);
    const blob = await fetch(Capacitor.convertFileSrc(response.file)).then((b) => b.blob());

    // upload ecg blob
    const description = `ECG-${dayjs().format('YYYY-MM-DDTHH:mm:ss')}`;
    let files: FileList;
    // eslint-disable-next-line
    files = {
      ...files,
      [0]: new File([blob], `${description}.pdf`, {
        type: 'application/pdf',
      }),
    };

    // upload document
    this.store.dispatch(
      UploadRequestActions.addEcgResultUpload({
        upload: { description, original_date: dayjs().format(), category: 'ecg_result', files },
        heartRate: response.heartRate !== 0 ? response.heartRate : null,
        determination: response.determination,
        device,
      }),
    );

    this.ECG_UPLOADING$.next(false);
    const subscription = this.ecgUploadRequest$
      .pipe(filter((u) => u !== undefined))
      .subscribe((request) => {
        // upload success case
        if (!request.canceled && request.progress === 100) {
          this.showEcgToast(
            'SUCCESS',
            transferringToast,
            subscription,
            request,
            response.heartRate,
            response.determination,
            device,
          );
          this.RECORD_RESULT_EVENT_LISTENER?.remove();
          subscription.unsubscribe();
        } else if (request.canceled) {
          // upload error case
          this.showEcgToast(
            'ERROR',
            transferringToast,
            subscription,
            request,
            response.heartRate,
            response.determination,
            device,
          );
          this.RECORD_RESULT_EVENT_LISTENER?.remove();
        }
      });
  }

  private async showEcgToast(
    type: 'SUCCESS' | 'ERROR',
    toastElement: HTMLIonToastElement,
    subscription: Subscription,
    request: UploadRequest,
    heartRate: number,
    determination: string,
    device: DeviceInformation,
  ) {
    let toast: HTMLIonToastElement;
    if (type === 'SUCCESS') {
      toast = await this.toastController.create({
        header: $localize`Dein EKG wurde erfolgreich aufgezeichnet und gespeichert.`,
        position: 'top',
        color: 'light',
        buttons: [
          {
            icon: 'close',
            role: 'close',
          },
        ],
      });
    } else {
      toast = await this.toastController.create({
        header: $localize`EKG konnte nicht gespeichert werden.`,
        position: 'top',
        color: 'warning',
        buttons: [
          {
            side: 'start',
            icon: 'close',
            role: 'close',
            handler: () => {
              subscription.unsubscribe();
            },
          },
          {
            side: 'end',
            icon: 'sync',
            handler: () => {
              this.store.dispatch(
                UploadRequestActions.addEcgResultUploadRequest({
                  request,
                  heartRate,
                  determination,
                  device,
                }),
              );
            },
          },
        ],
      });
    }
    toastElement.dismiss();
    toast.present();
  }

  async presentPopover() {
    const popover = await this.popoverController.create({
      component: BluetoothPermissionInfoComponent,
      cssClass: ['pro-popover'],
    });
    return await popover.present();
  }
}
