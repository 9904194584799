import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import * as MeasurementsActions from './measurements.actions';
import * as UploadRequestActions from 'store/documents-store/upload-request.actions';
import { catchComplete } from 'app/utils';
import {
  Measurement,
  MeasurementNormalized,
  ObservationResult,
  ObservationResultBloodPressure,
  ObservationResultEcg,
  ObservationResultSurvey,
  ObservationResultWeight,
} from 'app/models';
import * as dayjs from 'dayjs';
import { normalize, schema } from 'normalizr';

@Injectable()
export class MeasurementsEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadMeasurements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MeasurementsActions.loadMeasurements),
      mergeMap((action) =>
        this.apiService
          .getMeasurements(
            action.from,
            action.to || dayjs().format('YYYY-MM-DD'),
            action?.cache === false ? false : true,
            true
          )
          .pipe(
            map((data) =>
              MeasurementsActions.loadMeasurementsSuccess({
                ...this.createLoadSuccessObject(data.results, data.min_date),
              })
            ),
            catchError((error) => of(MeasurementsActions.loadMeasurementsFailure({ error }))),
            catchComplete(() => of(MeasurementsActions.loadMeasurementsComplete({ action })))
          )
      )
    );
  });

  reloadTodaysMeasurements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MeasurementsActions.addMeasurementsSuccess,
        MeasurementsActions.addEcgMeasurementsSuccess
      ),
      switchMap(() =>
        this.apiService.getMeasurements(dayjs().format('YYYY-MM-DD')).pipe(
          switchMap((data) => [
            MeasurementsActions.loadMeasurementsSuccess({
              ...this.createLoadSuccessObject(data.results, data.min_date),
            }),
          ]),
          catchError((error) => of(MeasurementsActions.loadMeasurementsFailure({ error })))
        )
      )
    )
  );

  addMeasurements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MeasurementsActions.addMeasurements,
        UploadRequestActions.addEcgResultUploadRequestSuccess
      ),
      mergeMap((action) =>
        this.apiService.addMeasurements(action.results).pipe(
          map((data) =>
            action.type === MeasurementsActions.addMeasurements.type
              ? MeasurementsActions.addMeasurementsPartialSuccess({ data })
              : MeasurementsActions.addEcgMeasurementsPartialSuccess({ data })
          ),
          catchError((error) => of(MeasurementsActions.addMeasurementsFailure({ error })))
        )
      )
    )
  );

  private normalizeResults(results: Measurement[]): {
    entities: {
      measurementEntities: { [key: string]: MeasurementNormalized };
      resultEntities: {
        [key: string]:
          | ObservationResult
          | ObservationResultWeight
          | ObservationResultBloodPressure
          | ObservationResultEcg
          | ObservationResultSurvey;
      };
    };
  } {
    const result = new schema.Entity<
      | ObservationResult
      | ObservationResultWeight
      | ObservationResultBloodPressure
      | ObservationResultEcg
      | ObservationResultSurvey
    >('resultEntities', {}, { idAttribute: (entity) => `${entity.id}_${entity.type}` });

    const measurement = new schema.Entity<MeasurementNormalized>(
      'measurementEntities',
      {
        results: [result],
      },
      { idAttribute: (entity: Record<string, number | string>) => `${entity.date}_${entity.type}` }
    );
    return normalize(results, new schema.Array(measurement));
  }

  private createLoadSuccessObject(results: Measurement[], minDate: string) {
    const normalizedData = this.normalizeResults(results);
    const resultEntities = normalizedData?.entities?.resultEntities || {};
    const measurementEntities = normalizedData?.entities?.measurementEntities || {};

    return {
      measurements: Object.values(measurementEntities),
      results: Object.values(resultEntities),
      minDate,
    };
  }
}
