import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PERSONAL_DATA_MODAL_COMBINED_PATH } from 'app/shared/shared.routes';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from 'store/api/api.service';
import { InfoFacade } from '../info-store/info.facade';
import * as ActivationsActions from './activations.actions';
import { ActivationsFacade } from './activations.facade';

@Injectable()
export class ActivationsEffects {
  constructor(
    private actions$: Actions,
    private activationsFacade: ActivationsFacade,
    private infoFacade: InfoFacade,
    private apiService: ApiService,
    private router: Router
  ) {}

  loadActivations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivationsActions.loadActivations, ActivationsActions.addActivationSuccess),
      switchMap(() =>
        this.apiService.getActivations().pipe(
          map((data) =>
            ActivationsActions.loadActivationsSuccess({
              activations: data.results,
              currentStatus: data.current_status,
            })
          ),
          catchError((error) => of(ActivationsActions.loadActivationsFailure({ error })))
        )
      )
    );
  });

  addActivation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivationsActions.addActivation),
      switchMap((action) =>
        this.apiService.addActivation(action.code, action.cleanPatientData).pipe(
          map((activation) => ActivationsActions.addActivationSuccess({ activation })),
          catchError((error) => of(ActivationsActions.addActivationFailure({ error })))
        )
      )
    );
  });

  validateTmcMandatoryData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ActivationsActions.loadActivationsSuccess),
        withLatestFrom(
          this.activationsFacade.activationCurrentIsTmc$,
          this.infoFacade.hasMandatoryDataForTmc$
        ),
        tap(([, isTmc, hasMandatoryDataForTmc]) => {
          if (isTmc && !hasMandatoryDataForTmc) {
            this.router.navigateByUrl(PERSONAL_DATA_MODAL_COMBINED_PATH);
          }
        })
      );
    },
    { dispatch: false }
  );
}
