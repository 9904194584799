import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Document, PageSize, UploadRequest } from 'app/models';
import { ApiService } from '../api/api.service';
import * as DocumentActions from 'store/documents-store/document.actions';
import * as fromDocuments from 'store/documents-store/document.reducer';
import * as UploadRequestActions from 'store/documents-store/upload-request.actions';
import * as fromUploadRequests from 'store/documents-store/upload-request.reducer';

@Injectable({
  providedIn: 'root',
})
export class DocumentsFacade {
  documents$ = this.store.pipe(select(fromDocuments.selectAll));
  uploadRequest$ = this.store.pipe(select(fromUploadRequests.selectUploadRequest));
  document$ = this.store.pipe(select(fromDocuments.selectDocumentById));
  loadingDocuments$ = this.store.pipe(select(fromDocuments.selectLoading));
  documentsAndRequests$ = this.store.pipe(
    select(fromDocuments.selectDocumentsAndRequestsByCategory)
  );
  documentsReportStationary$ = this.store.pipe(
    select(fromDocuments.selectDocumentsAndRequestsReportStationary)
  );
  documentsReportAmbulant$ = this.store.pipe(
    select(fromDocuments.selectDocumentsAndRequestsReportAmbulant)
  );
  documentsLaboratory$ = this.store.pipe(
    select(fromDocuments.selectDocumentsAndRequestsLaboratory)
  );
  documentsEcg$ = this.store.pipe(select(fromDocuments.selectDocumentsAndRequestsEcg));
  documentsImaging$ = this.store.pipe(select(fromDocuments.selectDocumentsAndRequestsImaging));
  documentsOther$ = this.store.pipe(select(fromDocuments.selectDocumentsAndRequestsOther));
  documentsProcarement$ = this.store.pipe(
    select(fromDocuments.selectDocumentsAndRequestsProcarement)
  );
  documentsQuestionnaire$ = this.store.pipe(
    select(fromDocuments.selectDocumentsAndRequestsQuestionnaire)
  );
  documentsRightsAndDisposals$ = this.store.pipe(
    select(fromDocuments.selectDocumentsAndRequestsRightsAndDisposals)
  );
  documentsEcgResult$ = this.store.pipe(select(fromDocuments.selectDocumentsAndRequestsEcgResult));
  documentError$ = this.store.pipe(select(fromDocuments.selectError));
  pagesByCategory$ = this.store.pipe(select(fromDocuments.selectPagesByCategory));

  constructor(
    private store: Store<fromDocuments.State | fromUploadRequests.State>,
    private apiService: ApiService
  ) {}

  loadDocuments(from?: string, to?: string, cache = true) {
    this.store.dispatch(DocumentActions.loadDocuments({ from, to, cache }));
  }

  loadDocumentsPages() {
    this.store.dispatch(DocumentActions.loadDocumentsPages());
  }

  loadDocumentsByPage(page: number, size: PageSize) {
    this.store.dispatch(DocumentActions.loadDocumentsByPage({ page, size }));
  }

  loadDocument(id: number) {
    this.store.dispatch(DocumentActions.loadDocument({ id }));
  }

  loadDocumentByRouteId() {
    this.store.dispatch(DocumentActions.loadDocumentByRouteId());
  }

  uploadDocuments(description: string, originalDate: string, category: string, files: FileList) {
    this.store.dispatch(
      UploadRequestActions.addUpload({ description, original_date: originalDate, category, files })
    );
  }

  deleteDocument(id: number) {
    this.store.dispatch(DocumentActions.deleteDocument({ id }));
  }

  deleteDocumentFromDatabase(id: number) {
    this.store.dispatch(DocumentActions.deleteDocumentFromDatabase({ id }));
  }

  deleteDocumentFromStore(id: number) {
    this.store.dispatch(DocumentActions.deleteDocumentFromStore({ id }));
  }

  retryUpload(request: UploadRequest) {
    this.store.dispatch(UploadRequestActions.addUploadRequest({ request }));
  }

  deleteUpload(id: number) {
    this.store.dispatch(UploadRequestActions.deleteUploadRequest({ id }));
  }

  cancelUpload(id: number) {
    this.store.dispatch(UploadRequestActions.cancelUploadRequest({ id }));
  }

  getDocument(id: number): Observable<Document> {
    return this.store.pipe(select(fromDocuments.selectDocument, { id }));
  }

  getFile(url: string) {
    return this.apiService.getFile(url);
  }

  requestMedicalReport() {
    return this.apiService.createMedicalReport();
  }
}
