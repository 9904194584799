import { AppStoreModule } from './app-store/app-store.module';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { ConnectionInterceptor, ErrorTrackingInterceptor } from 'app/interceptors';
import { EnvironmentService } from 'app/services/environment.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { alertEnterAnimation, alertLeaveAnimation } from './animations';
import { ErrorTrackingService } from './services/error-tracking.service';
import { QuestionnaireModule } from 'pages/questionnaire/questionnaire.module';
import { GlobalNoticeModule } from './global-notice/global-notice.module';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { MeasurementTransferModule } from './measurement-transfer/measurement-transfer.module';
import { I18nModule } from './i18n.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonIcon: 'assets/svgs/arrow-left-thin.svg',
      backButtonText: '',
      swipeBackEnabled: true,
      hardwareBackButton: true,
      rippleEffect: false,
      alertEnter: alertEnterAnimation,
      alertLeave: alertLeaveAnimation,
      mode: 'ios',
      animated: environment?.e2e ? false : true,
      innerHTMLTemplatesEnabled: true,
    }),
    AppRoutingModule,
    AppStoreModule,
    IonicStorageModule.forRoot({
      name: '__prodb',
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    QuestionnaireModule,
    GlobalNoticeModule,
    MeasurementTransferModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (environmentService: EnvironmentService) => () => environmentService.load(),
      deps: [EnvironmentService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ConnectionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorTrackingInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: ErrorTrackingService,
    },
    I18nModule.setLocale(),
    I18nModule.setLocaleId(),
    FingerprintAIO,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
