import {
  Action,
  createReducer,
  on,
  createFeatureSelector,
  createSelector,
  resultMemoize,
  createSelectorFactory,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { isEqual } from 'lodash-es';
import { Document, DocumentRequest, UploadRequest } from 'app/models';
import * as fromRouterSelectors from 'store/router-store/router.selectors';
import * as MeasurementActions from 'store/measurements-store/measurements.actions';
import * as DocumentActions from './document.actions';
import * as UploadRequestActions from './upload-request.actions';
import * as fromUploadRequests from './upload-request.reducer';
import * as dayjs from 'dayjs';
import { AnyFn } from '@ngrx/store/src/selector';
export const documentsFeatureKey = 'documents';

export interface CategoryPages {
  [category: string]: {
    page: number;
    pageSize: number;
    totalPages: number;
  };
}

export interface State extends EntityState<Document> {
  loading: boolean;
  error: Error | null;
  categoryPages: CategoryPages;
}

export const adapter: EntityAdapter<Document> = createEntityAdapter<Document>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
  categoryPages: undefined,
});

const documentReducer = createReducer(
  initialState,
  on(
    DocumentActions.loadDocumentsSuccess,
    UploadRequestActions.loadDocumentsSuccess,
    (state, action) => adapter.upsertMany(action.documents, state),
  ),
  on(DocumentActions.loadDocumentsPagesSuccess, (state, action) =>
    adapter.upsertMany(action.documents, {
      ...state,
      categoryPages: {
        ...state.categoryPages,
        [action.category]: {
          page: action.pages.page,
          pageSize: action.pages.pageSize,
          totalPages: action.pages.totalPages,
        },
      },
    }),
  ),
  on(DocumentActions.loadDocumentSuccess, (state, action) =>
    adapter.upsertOne(action.document, state),
  ),
  on(
    DocumentActions.deleteDocumentSuccess,
    DocumentActions.deleteDocumentFromStore,
    (state, action) => adapter.removeOne(action.id, state),
  ),
  on(
    DocumentActions.loadDocuments,
    DocumentActions.loadDocument,
    DocumentActions.loadDocumentsPages,
    DocumentActions.loadDocumentsByPage,
    DocumentActions.deleteDocument,
    DocumentActions.deleteDocumentFromDatabase,
    MeasurementActions.addMeasurementsSuccess,
    DocumentActions.loadDocumentByRouteId,
    (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    DocumentActions.loadDocumentsSuccess,
    DocumentActions.loadDocumentSuccess,
    DocumentActions.loadDocumentsComplete,
    DocumentActions.deleteDocumentSuccess,
    DocumentActions.deleteDocumentFromDatabaseSuccess,
    (state) => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    DocumentActions.loadDocumentsFailure,
    DocumentActions.loadDocumentFailure,
    DocumentActions.deleteDocumentFailure,
    (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return documentReducer(state, action);
}

export const customMemoized = (projectorFn: AnyFn) => resultMemoize(projectorFn, isEqual);

export const selectFeature = createFeatureSelector<State>('documents');

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);

export const selectDocument = createSelectorFactory<State, Document>(customMemoized)(
  selectEntities,
  (en: Dictionary<Document>, props: { id: number }) => en[props.id],
);

export const selectDocumentsAndRequests = createSelectorFactory<
  any,
  Array<UploadRequest | Document>
>(customMemoized)(
  fromUploadRequests.selectAll,
  selectAll,
  (requests: UploadRequest[], documents: Document[]): DocumentRequest[] => [
    ...requests,
    ...documents.sort((d1, d2) =>
      dayjs(d2.original_date, 'YYYY-MM-DD').diff(dayjs(d1.original_date, 'YYYY-MM-DD'), 'day'),
    ),
  ],
);

export const selectDocumentsAndRequestsByCategory = createSelector(
  selectDocumentsAndRequests,
  fromRouterSelectors.selectQueryParam('category'),
  (documents: Array<UploadRequest | Document>, category) =>
    category ? documents.filter((d) => d.category === category) : null,
);

export const selectDocumentsAndRequestsReportStationary = createSelector(
  selectDocumentsAndRequests,
  (documents: Array<UploadRequest | Document>) =>
    documents.filter((d) => d.category === 'report_stationary'),
);

export const selectDocumentsAndRequestsReportAmbulant = createSelector(
  selectDocumentsAndRequests,
  (documents: Array<UploadRequest | Document>) =>
    documents.filter((d) => d.category === 'report_ambulant'),
);

export const selectDocumentsAndRequestsLaboratory = createSelector(
  selectDocumentsAndRequests,
  (documents: Array<UploadRequest | Document>) =>
    documents.filter((d) => d.category === 'laboratory'),
);

export const selectDocumentsAndRequestsEcg = createSelector(
  selectDocumentsAndRequests,
  (documents: Array<UploadRequest | Document>) => documents.filter((d) => d.category === 'ecg'),
);

export const selectDocumentsAndRequestsImaging = createSelector(
  selectDocumentsAndRequests,
  (documents: Array<UploadRequest | Document>) => documents.filter((d) => d.category === 'imaging'),
);

export const selectDocumentsAndRequestsOther = createSelector(
  selectDocumentsAndRequests,
  (documents: Array<UploadRequest | Document>) => documents.filter((d) => d.category === 'other'),
);

export const selectDocumentsAndRequestsProcarement = createSelector(
  selectDocumentsAndRequests,
  (documents: Array<UploadRequest | Document>) =>
    documents.filter((d) => d.category === 'procarement'),
);

export const selectDocumentsAndRequestsQuestionnaire = createSelector(
  selectDocumentsAndRequests,
  (documents: Array<UploadRequest | Document>) =>
    documents.filter((d) => d.category === 'questionnaire'),
);

export const selectDocumentsAndRequestsRightsAndDisposals = createSelector(
  selectDocumentsAndRequests,
  (documents) => documents.filter((d) => d.category === 'rights_and_disposals'),
);

export const selectDocumentsAndRequestsEcgResult = createSelector(
  selectDocumentsAndRequests,
  (documents) => documents.filter((d) => d.category === 'ecg_result'),
);

export const selectDocumentById = createSelectorFactory(customMemoized)(
  selectAll,
  fromRouterSelectors.selectRouteParam('id'),
  (entities: Document[], id: string) => entities.find((entity) => +entity.id === +id),
);

export const selectError = createSelector(selectFeature, (state) => state.error);

export const selectCategoryPages = createSelector(selectFeature, (state) => state.categoryPages);

export const selectPagesByCategory = createSelector(
  selectCategoryPages,
  fromRouterSelectors.selectQueryParam('category'),
  (categoryPages, category) => (categoryPages !== undefined ? categoryPages[category] : undefined),
);
