export const LOGIN_URL = 'login';
export const BASE_APP_PATH = 'app';

export const SUBSCRIPTIONS_ACTIVIATION_PATH = 'subscriptions-activation';
export const SUBSCRIPTIONS_ACTIVIATION_SUCCESS_PATH = 'success';
export const SUBSCRIPTIONS_ACTIVIATION_SUCCESS_COMBINED_PATH = `/${BASE_APP_PATH}/${SUBSCRIPTIONS_ACTIVIATION_PATH}/${SUBSCRIPTIONS_ACTIVIATION_SUCCESS_PATH}`;

export const PERSONAL_DATA_MODAL_PATH = 'modal/personal-data';
export const PERSONAL_DATA_MODAL_COMBINED_PATH = `/${BASE_APP_PATH}/${PERSONAL_DATA_MODAL_PATH}`;

export const PROCAREMENT_HELP_URL = 'https://procarement.com/patientinnen/hilfe/';
