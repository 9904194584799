import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MeasurementResults } from 'app/models';
import * as MeasurementsActions from '../measurements.actions';
import * as MeasurementResultsActions from '../measurement-results-store/measurement-results.actions';

export const measurementResultsFeatureKey = 'measurementResults';

export interface State extends EntityState<MeasurementResults> {
  loading: boolean;
  error: Error | null;
}

const selectIdKey = (a: MeasurementResults) => {
  return `${a.id}_${a.type}`;
};

export const adapter: EntityAdapter<MeasurementResults> = createEntityAdapter<MeasurementResults>({
  selectId: selectIdKey,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

const measurementResultsReducer = createReducer(
  initialState,
  on(
    MeasurementResultsActions.loadMeasurementResults,
    MeasurementResultsActions.loadMeasurementResultsByPage,
    MeasurementResultsActions.updateMeasurementResult,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    MeasurementsActions.loadMeasurementsSuccess,
    MeasurementResultsActions.loadMeasurementResultsSuccess,
    MeasurementResultsActions.loadMeasurementResultsByPageSuccess,
    (state, action) => adapter.upsertMany(action.results, { ...state })
  ),
  on(MeasurementResultsActions.updateMeasurementResultSuccess, (state, action) =>
    adapter.upsertOne(action.measurementResult, state)
  ),
  on(
    MeasurementResultsActions.loadMeasurementResultsByPageFailure,
    MeasurementResultsActions.updateMeasurementResultFailure,
    MeasurementResultsActions.loadMeasurementResultsFailure,

    (state, action) => ({
      ...state,
      error: action.error,
    })
  ),
  on(
    MeasurementResultsActions.loadMeasurementResultsSuccess,
    MeasurementResultsActions.loadMeasurementResultsFailure,
    MeasurementResultsActions.loadMeasurementResultsByPageSuccess,
    MeasurementResultsActions.loadMeasurementResultsByPageFailure,
    MeasurementResultsActions.loadMeasurementResultsByPageComplete,
    MeasurementResultsActions.updateMeasurementResultSuccess,
    (state) => ({
      ...state,
      loading: false,
    })
  )
);

export const selectFeature = createFeatureSelector<State>('measurementResults');

export function reducer(state: State | undefined, action: Action) {
  return measurementResultsReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state) => state.loading);
