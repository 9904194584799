import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthFacade } from 'store/auth-store/auth.facade';
import { catchError, first } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { AppStoreFacade } from 'store-facade/app-store.facade';
import { of } from 'rxjs';

@Component({
  selector: 'pro-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.css'],
})
export class ConfirmPasswordComponent {
  @Input() textContent: string;

  isOffline$ = this.appStoreFacade.isOffline$;

  form = this.fb.group(
    { password: [''] },
    {
      validators: [
        (formGroup) => {
          const { password } = formGroup.value;
          // basic form validation
          return password !== '' ? null : { required: true };
        },
      ],
    }
  );

  hidePassword = true;
  loading = false;
  errorMessage = '';

  constructor(
    private fb: FormBuilder,
    private authFacade: AuthFacade,
    private appStoreFacade: AppStoreFacade,
    private modalController: ModalController
  ) {}

  /**
   * displays / hides passworts
   */
  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }

  /**
   * validates the form and attempts to update the password
   */
  submit() {
    const { password } = this.form.value;

    if (password === '') {
      this.form.controls.password.setErrors({
        required: true,
      });
    }

    if (
      this.form.valid &&
      this.errorMessage === '' &&
      this.loading === false &&
      this.appStoreFacade.isOffline$.value === false
    ) {
      this.loading = true;

      this.authFacade
        .checkPassword(password)
        .pipe(
          first(),
          catchError((err) => {
            this.errorMessage =
              err?.status === 401
                ? $localize`Ungültiges Passwort. Bitte versuche es erneut.`
                : $localize`Ein Fehler ist aufgetreten.`;
            return of(null);
          })
        )
        .subscribe((result) => {
          this.loading = false;
          if (result) {
            this.modalController.dismiss({ password });
          }
        });
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
