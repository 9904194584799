import { NgZone } from '@angular/core';
import { Observable, OperatorFunction, concat, defer } from 'rxjs';

export function enterZone(zone: NgZone) {
  return <T>(source: Observable<T>) =>
    new Observable<T>((observer) =>
      source.subscribe({
        next: (x) => zone.run(() => observer.next(x)),
        error: (err) => observer.error(err),
        complete: () => observer.complete(),
      })
    );
}

export function catchComplete<T, R>(fn: () => Observable<R>): OperatorFunction<T, T | R> {
  return (s) => {
    return concat(s, defer(fn));
  };
}

/**
 * creates a array of years from min-year to current one
 */
export function createDatepickerYears(minYear = new Date().getFullYear() - 100) {
  return Array.from(Array(new Date().getFullYear() - (minYear - 1))).map((_, i) => minYear + i);
}

/**
 * Tries to cast a string into a number equivalent.
 * Returns 0 if cast fails
 * @param value input string
 */
export function stringToNumber(value: string) {
  if (!value) {
    return 0;
  }

  const parts = value
    .toString()
    .replace(/,/g, '.')
    .split('.')
    .filter((part) => part !== '')
    .filter((part) => !isNaN(+part));

  if (parts.length === 0) {
    return 0;
  } else {
    if (+parts[0] <= 0) {
      return 0;
    }

    if (parts.length === 1) {
      return +parts[0];
    } else {
      return +`${parts[0]}.${parts[1]}`;
    }
  }
}

export const monthShortNames = [
  'Jan',
  'Feb',
  'Mrz',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dez',
];

/**
 * Compares two arrays of strings whether they have matching elements.
 */
export const isArrIncludedInArr = (arr1: string[], arr2: string[]) => {
  return arr1.some((item) => arr2.includes(item));
};

export const checkAgainstRegEx = (valueToCheck: string | number, regex: RegExp) =>
  (regex.exec(valueToCheck?.toString() || '') || []).length > 0;

export function applyNewDesign() {
  [
    'pro-welcome',
    'pro-registration-preconditions',
    'pro-registration-consents',
    'pro-registration-user-name',
    'pro-registration-user-data',
    'pro-registration-credentials',
    'pro-registration-confirm-email',
    'pro-user-profile',
    'pro-user-profile-edit',
    'pro-login',
    'pro-forgot-password',
    'pro-chronicle',
    'pro-chronicle-detail',
    'pro-personal-data-modal',
    'pro-security-info',
  ].map((selector) => {
    // checks if component ist visible
    const componentElement = document.querySelector(selector);
    // adds a class to the component element to apply the new design
    if (componentElement?.classList?.contains('pro-new-design') === false) {
      componentElement.classList.add('pro-new-design');
    }
  });
}
