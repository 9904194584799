import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MedicationIntake, PageSize } from 'app/models';
import * as MedicationIntakesActions from 'store/medication-intakes-store/medication-intakes.actions';
import * as fromMedicationIntakes from 'store/medication-intakes-store/medication-intakes.reducer';

@Injectable({
  providedIn: 'root',
})
export class MedicationIntakesFacade {
  medicationIntakes$ = this.store.pipe(
    select(fromMedicationIntakes.selectMedicationIntakesDailyWeeklyMonthly)
  );
  medicationIntakesAll$ = this.store.pipe(select(fromMedicationIntakes.selectAll));
  medicationIntakesAllWithoutOnDemand$ = this.store.pipe(
    select(fromMedicationIntakes.selectMedicationIntakesAllWithoutOnDemand)
  );
  medicationIntakesByDate$ = this.store.pipe(select(fromMedicationIntakes.selectMedicationIntakes));
  medicationIntakesWeekly$ = this.store.pipe(
    select(fromMedicationIntakes.selectMedicationIntakesWeekly)
  );
  medicationIntakesMonthly$ = this.store.pipe(
    select(fromMedicationIntakes.selectMedicationIntakesMonthly)
  );
  medicationIntakesMorning$ = this.store.pipe(
    select(fromMedicationIntakes.selectMedicationIntakesMorning)
  );
  medicationIntakesNoon$ = this.store.pipe(
    select(fromMedicationIntakes.selectMedicationIntakesNoon)
  );
  medicationIntakesEvening$ = this.store.pipe(
    select(fromMedicationIntakes.selectMedicationIntakesEvening)
  );
  medicationIntakesNight$ = this.store.pipe(
    select(fromMedicationIntakes.selectMedicationIntakesNight)
  );
  medicationIntakesFromYesterDayWithoutOnDemand$ = this.store.pipe(
    select(fromMedicationIntakes.selectMedicationIntakesFromYesterdayWithoutOnDemand)
  );
  loading$ = this.store.pipe(select(fromMedicationIntakes.selectLoading));

  constructor(private store: Store<fromMedicationIntakes.State>) {}

  loadMedicationIntakes(from: string, to?: string, cache = true) {
    this.store.dispatch(MedicationIntakesActions.loadMedicationIntakes({ from, to, cache }));
  }

  loadTodaysMedicationIntakes() {
    this.store.dispatch(MedicationIntakesActions.loadTodaysMedicationIntakes());
  }

  loadMedicationIntakesByPage(page: number, size: PageSize = 10) {
    this.store.dispatch(MedicationIntakesActions.loadMedicationIntakesByPage({ page, size }));
  }

  updateMedicationIntake(medicationIntake: MedicationIntake) {
    this.store.dispatch(MedicationIntakesActions.updateMedicationIntake({ medicationIntake }));
  }

  updateMedicationIntakes(
    medicationIntakes: MedicationIntake[],
    changedProp?: MedicationIntakesActions.MedicationIntakeKey
  ) {
    this.store.dispatch(
      MedicationIntakesActions.updateMedicationIntakes({ medicationIntakes, changedProp })
    );
  }
}
