import { AbstractControl, ValidationErrors } from '@angular/forms';
import { checkAgainstRegEx } from 'app/utils';

export const PHONE_NUMBER_VALIDATION_REGEXP = /^[0-9\s+\-/]+$/;

export const formErrors = {
  required: () => $localize`Darf nicht leer sein.`,
  min: ({ min }: { min: number | string }) => $localize`Mindesteingabe ist ${min}`,
  maxlength: ({ requiredLength }: { requiredLength: number | string }) =>
    $localize`Maximal ${requiredLength} Zeichen erlaubt.`,
  pattern: () => $localize`Ungültige Eingabe.`,
  passwordMissmatch: () => $localize`Passwörter stimmen nicht überein.`,
  custom: ({ message }: { message: string }) => message,
};

export function customValidator<T>(
  predicate: (value: T) => boolean,
  message: string,
  errorKey = 'custom'
) {
  return (control: AbstractControl<T>): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return predicate(control.value) ? { [errorKey]: { message: message } } : null;
  };
}
export const phoneValidator = customValidator<string>((value) => {
  return !checkAgainstRegEx(value, PHONE_NUMBER_VALIDATION_REGEXP);
}, $localize`Ungültige Telefonnummer.`);
