import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { EMPTY, of, from, timer } from 'rxjs';
import {
  withLatestFrom,
  switchMap,
  map,
  catchError,
  concatMap,
  filter,
  first,
  mergeMap,
  tap,
  skipWhile,
  delay,
  takeWhile,
} from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AliveCor } from 'alive-cor';
import { StorageFacade } from 'storage-store-facade/storage.facade';
import { BluetoothHelperService } from './bluetooth-helper.service';
import * as AuthActions from 'store/auth-store/auth.actions';
import * as fromDevices from './devices.reducer';
import * as DeviceActions from './devices.actions';
import * as Bm54Actions from './bm-store/bm54-store/bm54.actions';
import * as fromBm54 from './bm-store/bm54-store/bm54.reducer';
import * as Bm57Actions from './bm-store/bm57-store/bm57.actions';
import * as fromBm57 from './bm-store/bm57-store/bm57.reducer';
import * as Bm64Actions from './bm-store/bm64-store/bm64.actions';
import * as fromBm64 from './bm-store/bm64-store/bm64.reducer';
import * as Po60Actions from './po60-store/po60.actions';
import * as fromPo60 from './po60-store/po60.reducer';
import * as Bf720Actions from './bf720-store/bf720.actions';
import * as fromBf720 from './bf720-store/bf720.reducer';
import { DeviceTypeTranslationPipe } from 'store/devices-store/devices-store-shared/device-type-translation/device-type-translation.pipe';
import { DeviceType } from './devices.model';
import { AppStoreFacade } from '../app-store-facade/app-store.facade';

type Device = 'BM54' | 'BM57' | 'PO60' | 'BF720';

@Injectable()
export class DevicesEffects {
  toasts: {
    BM54: HTMLIonToastElement | any;
    BM57: HTMLIonToastElement | any;
    BM64: HTMLIonToastElement | any;
    PO60: HTMLIonToastElement | any;
    BF720: HTMLIonToastElement | any;
  } = {
    ['BM54']: null,
    ['BM57']: null,
    ['BM64']: null,
    ['PO60']: null,
    ['BF720']: null,
  };

  constructor(
    private actions$: Actions,
    private storage: StorageFacade,
    private store: Store<fromDevices.State>,
    private bluetoothLe: BluetoothLE,
    private bluetoothHelper: BluetoothHelperService,
    private toastController: ToastController,
    private router: Router,
    private deviceTypeTranslation: DeviceTypeTranslationPipe,
    private appStoreFacade: AppStoreFacade
  ) {}

  startScan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeviceActions.startScan),
      switchMap((action) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothLe.isScanning()),
          concatMap((r) => (r.isScanning ? this.bluetoothLe.stopScan() : of(r))), // stop startScanning$
          concatMap(() => this.bluetoothLe.startScan({})),
          skipWhile((s) => s.status !== 'scanResult' || s.name !== action.device),
          first(),
          map((result) => {
            return DeviceActions.startScanSuccess({
              name: action.device,
              address: result.address,
            });
          }),
          catchError((error: any) => of(DeviceActions.startScanFailure({ error })))
        )
      )
    );
  });

  stopScan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DeviceActions.stopScan,
        DeviceActions.startScanSuccess,
        DeviceActions.startScanManualMeasurements,
        Bm54Actions.startScanningSuccess,
        Bm57Actions.startScanningSuccess,
        Bm64Actions.startScanningSuccess,
        Po60Actions.startScanningSuccess,
        Bf720Actions.startScanningSuccess
      ),
      switchMap((action) =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothLe.isScanning()),
          concatMap((r) =>
            r.isScanning ? this.bluetoothLe.stopScan() : of({ status: 'Not scanning' })
          ),
          map((r) => {
            if (action.type === DeviceActions.stopScan.type) {
              return DeviceActions.stopScanSuccess({ status: r.status });
            } else if (action.type === DeviceActions.startScanManualMeasurements.type) {
              return DeviceActions.startScan({ device: action.device });
            } else {
              return (action as any).name === 'BM54'
                ? Bm54Actions.loadMeasurements({ address: (action as any).address })
                : (action as any).name === 'BM57'
                ? Bm57Actions.loadMeasurements({ address: (action as any).address })
                : (action as any).name === 'BM64'
                ? Bm64Actions.loadMeasurements({ address: (action as any).address })
                : (action as any).name === 'PO60'
                ? Po60Actions.loadMeasurements({ address: (action as any).address })
                : (action as any).type === DeviceActions.startScanSuccess.type
                ? Bf720Actions.loadUserList({ address: (action as any).address })
                : Bf720Actions.loginCurrentUser();
            }
          }),
          catchError((error: any) =>
            action.type === DeviceActions.startScanManualMeasurements.type
              ? of(DeviceActions.startScanManualMeasurementsFailure({ error }))
              : of(DeviceActions.stopScanFailure({ error }))
          )
        )
      )
    );
  });

  startScanning$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        Bm54Actions.startScanning,
        Bm57Actions.startScanning,
        Bm64Actions.startScanning,
        Po60Actions.startScanning,
        Bf720Actions.startScanning,
        Bf720Actions.loadMeasurementsSuccess,
        DeviceActions.stopScanningSuccess,
        DeviceActions.startScanFailure,
        Bm54Actions.deleteDeviceSuccess,
        Bm57Actions.deleteDeviceSuccess,
        Po60Actions.deleteDeviceSuccess,
        Bf720Actions.deleteDeviceSuccess
      ),
      withLatestFrom(this.store.pipe(select(fromDevices.selectScanningDevices))),
      switchMap(([, devices]) =>
        timer(30000, 30000).pipe(
          withLatestFrom(
            this.store.pipe(select(fromDevices.selectScanning)),
            this.store.pipe(select(fromDevices.selectPauseScanning)),
            this.appStoreFacade.appIsActiveAndLoggedIn$
          ),
          filter(
            ([, scanning, pauseScanning, activeAndLoggedIn]) =>
              !scanning && !pauseScanning && activeAndLoggedIn
          ),
          takeWhile(() => devices.length > 0),
          switchMap(() =>
            this.bluetoothHelper.isInitializedInitialize().pipe(
              withLatestFrom(this.appStoreFacade.appIsActiveAndLoggedIn$),
              filter(([, activeAndLoggedIn]) => activeAndLoggedIn === true),
              concatMap(() => this.bluetoothLe.isScanning()),
              concatMap((s) => (s.isScanning ? this.bluetoothLe.stopScan() : of(s))),
              concatMap(() => this.bluetoothLe.startScan({})),
              skipWhile((s) => s.status !== 'scanResult'),
              filter((s) => devices.some((d) => d.name === s.name)),
              map((result) => {
                return result.name === 'BM54'
                  ? Bm54Actions.startScanningSuccess({
                      name: result.name,
                      address: result.address,
                    })
                  : result.name === 'BM57'
                  ? Bm57Actions.startScanningSuccess({
                      name: result.name,
                      address: result.address,
                    })
                  : result.name === 'BM64'
                  ? Bm64Actions.startScanningSuccess({
                      name: result.name,
                      address: result.address,
                    })
                  : result.name === 'PO60'
                  ? Po60Actions.startScanningSuccess({
                      name: result.name,
                      address: result.address,
                    })
                  : Bf720Actions.startScanningSuccess({
                      name: result.name,
                      address: result.address,
                    });
              }),
              catchError((error: any) => of(DeviceActions.startScanningFailure({ error })))
            )
          )
        )
      )
    );
  });

  stopScanning$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        Bm54Actions.startScanning,
        Bm57Actions.startScanning,
        Bm64Actions.startScanning,
        Po60Actions.startScanning,
        Bf720Actions.startScanning,
        Bf720Actions.loadMeasurementsSuccess,
        DeviceActions.stopScanningSuccess
      ),
      delay(10000), // time after which a scan interval is stopped
      switchMap(() =>
        this.bluetoothHelper.isInitializedInitialize().pipe(
          concatMap(() => this.bluetoothLe.stopScan()),
          map(() => {
            return DeviceActions.stopScanningSuccess();
          }),
          catchError((error: any) => of(DeviceActions.stopScanningFailure({ error })))
        )
      )
    );
  });

  deleteDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeviceActions.deleteDevice),
      mergeMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.select(fromDevices.selectDevice({ id: action.id })))
        )
      ),
      switchMap(([action]) =>
        of({}).pipe(
          map(() => {
            return action.id === 'BM54'
              ? Bm54Actions.deleteDevice()
              : action.id === 'BM57'
              ? Bm57Actions.deleteDevice()
              : action.id === 'BM64'
              ? Bm64Actions.deleteDevice()
              : action.id === 'PO60'
              ? Po60Actions.deleteDevice()
              : Bf720Actions.deleteDevice();
          })
        )
      )
    );
  });

  setStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        Bm54Actions.loadMeasurementsSuccess,
        Bm57Actions.loadMeasurementsSuccess,
        Bm64Actions.loadMeasurementsSuccess,
        Po60Actions.loadMeasurementsSuccess,
        Bf720Actions.loadMeasurementsSuccess,
        Bm54Actions.deleteDeviceSuccess,
        Bm57Actions.deleteDeviceSuccess,
        Bm64Actions.deleteDeviceSuccess,
        Po60Actions.deleteDeviceSuccess,
        Bf720Actions.deleteDeviceSuccess
      ),
      withLatestFrom(
        this.store.pipe(select(fromBm54.selectFeature)),
        this.store.pipe(select(fromBm57.selectFeature)),
        this.store.pipe(select(fromBm64.selectFeature)),
        this.store.pipe(select(fromPo60.selectFeature)),
        this.store.pipe(select(fromBf720.selectFeature))
      ),
      switchMap(([, bm54State, bm57State, bm64State, po60State, bf720State]) =>
        from(
          this.storage.set('devices', [bm54State, bm57State, bm64State, po60State, bf720State])
        ).pipe(map(() => DeviceActions.setStorageSuccess()))
      )
    );
  });

  loadDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeviceActions.loadDevices),
      switchMap(() =>
        from(this.storage.get('devices')).pipe(
          switchMap((devices: any[]): any[] => {
            const actions: any[] = [];
            if (devices) {
              devices.map((state) => {
                if (state.name === 'BM54') {
                  actions.push(Bm54Actions.addState({ state }));
                } else if (state.name === 'BM57') {
                  actions.push(Bm57Actions.addState({ state }));
                } else if (state.name === 'BM64') {
                  actions.push(Bm64Actions.addState({ state }));
                } else if (state.name === 'PO60') {
                  actions.push(Po60Actions.addState({ state }));
                } else {
                  actions.push(Bf720Actions.addState({ state }));
                }
              });
            }
            return actions;
          })
        )
      )
    );
  });

  disconnectDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signOutSuccess),
      switchMap(() =>
        from(this.storage.get('devices')).pipe(
          switchMap((devices: any[]): any[] => {
            const actions: any[] = [];

            if (devices) {
              devices
                .filter((s) => s.address)
                .map((state) => {
                  if (state.name === 'BM54') {
                    actions.push(Bm54Actions.disconnectDevice({ address: state.address }));
                  } else if (state.name === 'BM57') {
                    actions.push(Bm57Actions.disconnectDevice({ address: state.address }));
                  } else if (state.name === 'BM64') {
                    actions.push(Bm64Actions.disconnectDevice({ address: state.address }));
                  } else if (state.name === 'PO60') {
                    actions.push(Po60Actions.disconnectDevice({ address: state.address }));
                  } else {
                    actions.push(Bf720Actions.disconnectDevice({ address: state.address }));
                  }
                });
            }
            return actions;
          })
        )
      )
    );
  });

  dismissAliveCor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeviceActions.dismissAliveCor),
      switchMap(() =>
        of(AliveCor.dismiss()).pipe(
          map(() => DeviceActions.dismissAliveCorSuccess()),
          catchError((error: any) => of(DeviceActions.dismissAliveCorFailure({ error })))
        )
      )
    );
  });

  showAddMeasurementNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        Bm54Actions.loadMeasurementsSuccess,
        Bm57Actions.loadMeasurementsSuccess,
        Bm64Actions.loadMeasurementsSuccess,
        Po60Actions.loadMeasurementsSuccess,
        Bf720Actions.loadMeasurementsSuccess
      ),
      mergeMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(fromDevices.selectInitialising)),
            action.type === Bm54Actions.loadMeasurementsSuccess.type
              ? this.store.pipe(select(fromBm54.selectFeature))
              : action.type === Bm57Actions.loadMeasurementsSuccess.type
              ? this.store.pipe(select(fromBm57.selectFeature))
              : action.type === Bm64Actions.loadMeasurementsSuccess.type
              ? this.store.pipe(select(fromBm64.selectFeature))
              : action.type === Po60Actions.loadMeasurementsSuccess.type
              ? this.store.pipe(select(fromPo60.selectFeature))
              : action.type === Bf720Actions.loadMeasurementsSuccess.type
              ? this.store.pipe(select(fromBf720.selectFeature))
              : EMPTY
          )
        )
      ),
      map(([, init, state]) => {
        if (!init) {
          this.showAddMeasurementToast(state);
        }
        return DeviceActions.loadMeasurementsSuccess();
      })
    );
  });

  showTransferringErrorNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(Po60Actions.transferringError),
        mergeMap((action) =>
          of(action).pipe(
            withLatestFrom(
              action.type === Po60Actions.transferringError.type
                ? this.store.pipe(select(fromPo60.selectFeature))
                : EMPTY
            )
          )
        ),
        tap(([, state]) => {
          this.showToast(state.name as Device, true, true, state.type);
        })
      );
    },
    { dispatch: false }
  );

  showTransferringNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          Bm54Actions.loadMeasurementsSubscribed,
          Bm57Actions.loadMeasurementsSubscribed,
          Bm64Actions.loadMeasurementsSubscribed,
          Bm54Actions.loadMeasurementsSuccess,
          Bm57Actions.loadMeasurementsSuccess,
          Bm64Actions.loadMeasurementsSuccess,
          Po60Actions.loadMeasurementsSubscribedResult,
          Po60Actions.loadMeasurementsSuccess
        ),
        mergeMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store.pipe(select(fromDevices.selectInitialising)),
              action.type === Bm54Actions.loadMeasurementsSuccess.type ||
                action.type === Bm54Actions.loadMeasurementsSubscribed.type
                ? this.store.pipe(select(fromBm54.selectFeature))
                : action.type === Bm57Actions.loadMeasurementsSuccess.type ||
                  action.type === Bm57Actions.loadMeasurementsSubscribed.type
                ? this.store.pipe(select(fromBm57.selectFeature))
                : action.type === Bm64Actions.loadMeasurementsSuccess.type ||
                  action.type === Bm64Actions.loadMeasurementsSubscribed.type
                ? this.store.pipe(select(fromBm64.selectFeature))
                : action.type === Po60Actions.loadMeasurementsSuccess.type ||
                  action.type === Po60Actions.loadMeasurementsSubscribedResult.type
                ? this.store.pipe(select(fromPo60.selectFeature))
                : EMPTY
            )
          )
        ),
        filter(([, init]) => !init),
        tap(([action, init, state]) => {
          if (
            (action.type === Bm54Actions.loadMeasurementsSubscribed.type ||
              action.type === Bm57Actions.loadMeasurementsSubscribed.type ||
              action.type === Bm64Actions.loadMeasurementsSubscribed.type ||
              action.type === Po60Actions.loadMeasurementsSubscribedResult.type) &&
            !this.toasts[state.name as Device]
          ) {
            this.showToast(state.name as Device, true, false, state.type);
          } else if (!init) {
            this.showToast(state.name as Device, false, false, state.type);
          }
        })
      );
    },
    { dispatch: false }
  );

  private async showAddMeasurementToast(state: fromBm54.State | fromPo60.State | fromBf720.State) {
    if (this.toasts[state.name as Device]) {
      this.toasts[state.name as Device]?.dismiss();
      this.toasts[state.name as Device] = null;
    }

    const resultsLength = state.lastResults.length;
    const message =
      resultsLength === 0
        ? $localize`Es sind keine neuen Messwerte auf dem Gerät vorhanden`
        : resultsLength === 1
        ? $localize`1 neuer Messwert`
        : $localize`${resultsLength} neue Messwerte`;

    const toast = await this.toastController.create({
      header: `${this.deviceTypeTranslation.transform(state.type)}`,
      message,
      position: 'top',
      color: 'light',
      buttons:
        state.lastResults.length > 0
          ? [
              {
                icon: 'open-outline',
                handler: () => {
                  this.router.navigate(['app/devices/results', state.name as Device], {
                    queryParamsHandling: 'merge',
                    queryParams: { canDeactivate: false },
                  });
                },
              },
            ]
          : [
              {
                icon: 'close',
                role: 'close',
              },
            ],
    });
    toast.present();
  }

  private async showToast(device: Device, showToast: boolean, error = false, type: DeviceType) {
    if (showToast && !this.toasts[device] && !error) {
      this.toasts[device] = !this.toasts[device] ? ' ' : this.toasts[device]; // set empty toast before promise resolves
      this.toasts[device] = await this.toastController.create({
        header: `${this.deviceTypeTranslation.transform(type)}`,
        message: $localize`Messwerte werden übertragen.`,
        position: 'top',
        color: 'light',
        buttons: [
          {
            icon: 'close',
            role: 'close',
          },
        ],
      });
      this.toasts[device].present();
    } else if (error) {
      this.toasts[device] = await this.toastController.create({
        header: $localize`${this.deviceTypeTranslation.transform(type)} Übertragung unterbrochen.`,
        message: $localize`Starten Sie die Übertragung erneut und verringern Sie den Abstand zum Telefon.`,
        position: 'top',
        color: 'light',
        buttons: [
          {
            icon: 'close',
            role: 'close',
          },
        ],
      });
      this.toasts[device].present();
    }
  }
}
